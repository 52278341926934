<template>
	<v-container fluid>
		<v-expansion-panels focusable>
			<v-expansion-panel>
				<v-expansion-panel-header
					class="text-uppercase text-h5 font-weight-regular primary--text"
				>
					Fournisseurs
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<Fournisseurs />
				</v-expansion-panel-content>
			</v-expansion-panel>
			<v-expansion-panel>
				<v-expansion-panel-header
					class="text-uppercase text-h5 font-weight-regular primary--text"
				>
					Clients
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<Clients />
				</v-expansion-panel-content>
			</v-expansion-panel>
			<v-expansion-panel>
				<v-expansion-panel-header
					class="text-uppercase text-h5 font-weight-regular primary--text"
				>
					Catégories
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<Categories />
				</v-expansion-panel-content>
			</v-expansion-panel>
			<v-expansion-panel>
				<v-expansion-panel-header
					class="text-uppercase text-h5 font-weight-regular primary--text"
				>
					Emplacements
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<Emplacements />
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</v-container>
</template>
<script>
import Clients from "@/components/Utilisateur/Gestion/Clients.vue";
import Fournisseurs from "@/components/Utilisateur/Gestion/Fournisseurs.vue";
import Categories from "@/components/Utilisateur/Gestion/Categories.vue";
import Emplacements from "@/components/Utilisateur/Gestion/Emplacements.vue";

export default {
	components: { Clients, Fournisseurs, Categories, Emplacements },
};
</script>
