<template>
	<div>
		<nav>
			<v-snackbar
				:color="snackbarColor"
				v-model="snackbar"
				:timeout="2500"
				top
			>
				{{ snackbarMessage }}
				<v-btn text color="white" @click="snackbar = false">
					<v-icon center>close</v-icon>
				</v-btn>
			</v-snackbar>
			<v-app-bar app>
				<v-app-bar-nav-icon
					class="primary--text"
					v-on:click="drawer = !drawer"
				></v-app-bar-nav-icon>
				<v-toolbar-title class="primary--text">
					<span class="font-weight-light">ISL</span>
					<span>WMS </span>
					<span class="font-weight-light">{{ user.depot }}</span>
				</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-title>
					<span class="font-weight-light primary--text">{{
						user.mail
					}}</span>
				</v-toolbar-title>
				<v-btn text color="primary" v-on:click="dialog = true">
					<span>Changer mot de passe</span>
					<v-icon right>vpn_key</v-icon>
				</v-btn>
				<v-btn text color="primary" v-on:click="logout">
					<span>Déconnexion</span>
					<v-icon right>exit_to_app</v-icon>
				</v-btn>
			</v-app-bar>
			<v-navigation-drawer app v-model="drawer" class="grey lighten-2">
				<v-list>
					<v-list-item-group
						v-model="model"
						active-class="bg-active"
						color="primary"
					>
						<v-list-item
							v-for="(link, i) in links"
							:key="i"
							v-on:click="selection(link.tag)"
						>
							<v-list-item-action>
								<v-icon>{{ link.icon }}</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title>{{
									link.text
								}}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list-item-group>
				</v-list>
			</v-navigation-drawer>
		</nav>
		<v-dialog v-model="dialog" max-width="500px">
			<v-card>
				<v-card-title>
					<span class="text-h5 primary--text"
						>Changer de mot de passe</span
					>
				</v-card-title>

				<v-card-text>
					<v-form>
						<v-text-field
							prepend-icon="lock"
							label="Nouveau mot de passe"
							type="password"
							v-model="newPassword"
							required
						></v-text-field>
						<v-text-field
							prepend-icon="lock"
							label="Retaper le nouveau mot de passe"
							type="password"
							v-model="newPassword2"
							required
						></v-text-field>
					</v-form>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="close">Annuler</v-btn>
					<v-btn color="primary" text @click="save">Valider</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import router from "@/router";

export default {
	props: ["user", "selected"],
	data() {
		return {
			snackbarColor: null,
			snackbarMessage: null,
			snackbar: false,
			dialog: false,
			drawer: false,
			newPassword: null,
			newPassword2: null,
			model: 0,
			links: [
				{
					icon: "dashboard",
					text: "Tableau de bord",
					tag: "dashboard",
				},
				{
					icon: "assignment",
					text: "Pré-réception",
					tag: "prereception",
				},
				{ icon: "input", text: "Réception", tag: "reception" },
				{
					icon: "location_on",
					text: "Localisation",
					tag: "localisation",
				},
				{
					icon: "mdi-truck-delivery",
					text: "Préparation",
					tag: "preparation",
				},
				{ icon: "history", text: "Historique", tag: "history" },
				{ icon: "settings", text: "Gestion", tag: "gestion" },
			],
		};
	},
	watch: {
		dialog(val) {
			val || this.close();
		},
	},
	methods: {
		selection(tag) {
			this.drawer = !this.drawer;
			this.$emit("selection", tag);
		},
		logout() {
			this.axios
				.get(process.env.VUE_APP_API_URL + "/user/logout", {
					withCredentials: true,
				})
				.then(() => {
					router.push("/login");
				});
		},
		close() {
			this.newPassword = null;
			this.newPassword2 = null;
			this.dialog = false;
		},

		save() {
			this.axios
				.post(
					process.env.VUE_APP_API_URL + "/user/changePassword",
					{
						newPassword: this.newPassword,
						newPassword2: this.newPassword2,
					},
					{
						withCredentials: true,
					}
				)
				.then((res) => {
					let result = res.data;
					if (result.success == false) {
						this.newPassword = null;
						this.newPassword2 = null;
						this.snackbarColor = "error";
						this.snackbarMessage = result.msg;
						this.snackbar = true;
					} else {
						this.newPassword = null;
						this.newPassword2 = null;
						this.snackbarColor = "success";
						this.snackbarMessage = result.msg;
						this.snackbar = true;
						this.close();
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
	},
};
</script>
