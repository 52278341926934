<template>
	<div>
		<v-snackbar :color="snackbarColor" v-model="snackbar" :timeout="1500">
			{{ snackbarMessage }}
			<v-btn text color="white" @click="snackbar = false">
				<v-icon center>close</v-icon>
			</v-btn>
		</v-snackbar>
		<v-container grid-list-md>
			<v-file-input
				multiple
				truncate-length="15"
				@change="importFiles"
				accept=".xml"
				ref="inputFiles"
			>
			</v-file-input>
			<v-data-table
				v-if="showTable"
				:headers="headers"
				:items="items"
				:footer-props="{
					itemsPerPageText: 'Nombre de contremarques par page',
					itemsPerPageOptions: [
						25,
						50,
						100,
						{ text: 'Tout', value: -1 },
					],
				}"
			>
				<template slot="no-data">
					<v-alert :value="true" color="primary" icon="info" outlined
						>Pas de produit dans le dépôt
					</v-alert>
				</template>
				<template slot="footer.page-text" slot-scope="props">
					Lignes {{ props.pageStart }} à {{ props.pageStop }} sur
					{{ props.itemsLength }}
				</template>
			</v-data-table>

			<div class="text-center">
				<v-btn color="primary" text @click="close">Annuler</v-btn>
				<v-btn color="primary" @click="save">Sauvegarder</v-btn>
			</div>
		</v-container>
	</div>
</template>

<script>
export default {
	data: function () {
		return {
			snackbarColor: null,
			snackbarMessage: null,
			snackbar: false,
			dialog: false,
			resolve: null,
			reject: null,
			message: null,
			showTable: false,
			items: [],
			headers: [
				{ text: "Catégorie", value: "categorie" },
				{ text: "Détails", value: "details" },
				{ text: "Fournisseur", value: "fournisseur" },
				{ text: "Client", value: "client" },
				{ text: "Contremarque", value: "contremarque" },
				{ text: "Quantité", value: "quantity" },
				{ text: "Unité", value: "unity" },
				{ text: "Réf", value: "ref" },
			],
			options: {
				color: "primary",
			},
		};
	},
	watch: {
		dialog(val) {
			val || this.close();
		},
	},
	methods: {
		importFiles(files) {
			if (files.length > 0) {
				this.showTable = true;
				var items = [];
				for (let i = 0; i < files.length; i++) {
					var self = this;
					var reader = new FileReader();
					reader.addEventListener("load", async function () {
						self.xml = reader.result;
						let parser = new DOMParser();
						let doc = parser.parseFromString(self.xml, "text/xml");
						let baseItem = {
							categorie: "CUISINE",
							details: "",
							quantity: 1,
							unity: "palette",
							fournisseur: "Nobilia",
							client: "NOBLESSA",
							contremarque: "",
							status: "prereception",
							outDate: null,
							ref: "",
						};
						let n = doc.getElementsByTagName("BNAME").length;
						for (let i = 0; i < n; i++) {
							var item = Object.assign({}, baseItem);
							item["contremarque"] =
								doc.getElementsByTagName("BNAME")[
									i
								].childNodes[0].nodeValue;
							item["ref"] =
								doc.getElementsByTagName("VBELN_VA")[
									i
								].childNodes[0].nodeValue;
							items.push(item);
						}
					});
					reader.readAsText(files[i]);
				}
				this.items = items;
			} else {
				this.showTable = false;
				this.items = [];
			}
		},
		open() {
			this.dialog = true;
			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			});
		},
		close() {
			this.dialog = false;
			this.items = [];
			this.showTable = false;
			this.$refs.inputFiles.clearableCallback();
		},
		save() {
			// Chaque item contient une seule contremarque
			var allContremarques = [];
			this.items.forEach((item) => {
				let cmq = {
					name: item.contremarque,
					client: item.client,
					items: [],
					facturation: "60fbfe01cb825d6785841a84", // Noblessa
					ref: item.ref,
				};
				allContremarques.push(cmq);
			});
			this.axios
				.put(
					process.env.VUE_APP_API_URL + "/api/contremarques/add",
					allContremarques,
					{ withCredentials: true }
				)
				.then((cmqInserted) => {
					cmqInserted.data.forEach((cmq) => {
						this.items.find(
							(item) =>
								item.contremarque == cmq.name &&
								item.ref == cmq.ref
						).contremarque = cmq._id;
					});
					this.axios
						.put(
							process.env.VUE_APP_API_URL + "/api/items/add",
							this.items,
							{
								withCredentials: true,
							}
						)
						.then((itemsInserted) => {
							var promises = [];
							itemsInserted.data.forEach((item) => {
								promises.push(
									this.axios.post(
										process.env.VUE_APP_API_URL +
											"/api/contremarques/update",
										{
											cmq: item.contremarque,
											id: [item._id],
										},
										{
											withCredentials: true,
										}
									)
								);
							});
							Promise.all(promises)
								.then(() => {
									this.items = [];
									this.showTable = false;
									this.$refs.inputFiles.clearableCallback();
									this.snackbarColor = "success";
									this.snackbarMessage =
										"Contremarques importés";
									this.snackbar = true;
								})
								.catch((err) => {
									this.items = [];
									this.showTable = false;
									this.$refs.inputFiles.clearableCallback();
									this.snackbarColor = "error";
									this.snackbarMessage = "Erreur " + error;
									this.snackbar = true;
									console.log(err);
								});
						});
				})
				.catch((error) => {
					this.items = [];
					this.showTable = false;
					this.$refs.inputFiles.clearableCallback();
					this.snackbarColor = "error";
					this.snackbarMessage = "Erreur " + error;
					this.snackbar = true;
					console.log(error);
				});
			// this.close()
		},
	},
};
</script>
