var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"timeout":1500},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" "),_c('v-btn',{attrs:{"text":"","color":"white"},on:{"click":function($event){_vm.snackbar = false}}},[_c('v-icon',{attrs:{"center":""}},[_vm._v("close")])],1)],1),_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-file-input',{ref:"inputFiles",attrs:{"multiple":"","truncate-length":"15","accept":".xml"},on:{"change":_vm.importFiles}}),(_vm.showTable)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":{
				itemsPerPageText: 'Nombre de contremarques par page',
				itemsPerPageOptions: [
					25,
					50,
					100,
					{ text: 'Tout', value: -1 } ],
			}},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" Lignes "+_vm._s(props.pageStart)+" à "+_vm._s(props.pageStop)+" sur "+_vm._s(props.itemsLength)+" ")]}}],null,false,894606870)},[_c('template',{slot:"no-data"},[_c('v-alert',{attrs:{"value":true,"color":"primary","icon":"info","outlined":""}},[_vm._v("Pas de produit dans le dépôt ")])],1)],2):_vm._e(),_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.close}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v("Sauvegarder")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }