<template>
	<v-container fluid>
		<v-expansion-panels focusable>
			<v-expansion-panel>
				<v-expansion-panel-header
					class="text-uppercase text-h5 font-weight-regular primary--text"
				>
					Administrateurs
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<administrateurs />
				</v-expansion-panel-content>
			</v-expansion-panel>
			<v-expansion-panel>
				<v-expansion-panel-header
					class="text-uppercase text-h5 font-weight-regular primary--text"
				>
					Gestionnaires de dépôt
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<gestionnaires ref="gestionnaires" />
				</v-expansion-panel-content>
			</v-expansion-panel>
			<v-expansion-panel>
				<v-expansion-panel-header
					class="text-uppercase text-h5 font-weight-regular primary--text"
				>
					Comptables
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<comptables />
				</v-expansion-panel-content>
			</v-expansion-panel>
			<v-expansion-panel>
				<v-expansion-panel-header
					class="text-uppercase text-h5 font-weight-regular primary--text"
				>
					Dépôts
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<depots @fetchDepots="fetchDepots()" />
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</v-container>
</template>
<script>
import Administrateurs from "@/components/Administrateur/Gestion/Administrateurs.vue";
import Gestionnaires from "@/components/Administrateur/Gestion/Gestionnaires.vue";
import Depots from "@/components/Administrateur/Gestion/Depots.vue";
import Comptables from "@/components/Administrateur/Gestion/Comptables.vue";
export default {
	components: { Administrateurs, Gestionnaires, Depots, Comptables },
	methods: {
		fetchDepots() {
			this.$refs.gestionnaires.fetchDepots();
		},
	},
};
</script>
