<template>
	<div>
		<confirm ref="confirm"></confirm>
		<v-snackbar :color="snackbarColor" v-model="snackbar" :timeout="1500">
			{{ snackbarMessage }}
			<v-btn text color="white" @click="snackbar = false">
				<v-icon center>close</v-icon>
			</v-btn>
		</v-snackbar>
		<v-form>
			<v-container fluid>
				<v-subheader class="text-h6">Ajouter un client</v-subheader>
				<v-row>
					<v-col xs12 md6>
						<v-text-field
							v-model="client.name"
							label="Nom"
							id="name"
							required
							autocomplete="off"
						>
						</v-text-field>
					</v-col>

					<v-col xs12 md6>
						<v-text-field
							v-model="client.mail"
							label="Mail"
							id="mail"
							required
							autocomplete="off"
						>
						</v-text-field>
					</v-col>
				</v-row>
				<div class="text-center mb-4">
					<v-btn outlined color="primary" v-on:click="addClient"
						>Ajouter</v-btn
					>
				</div>
			</v-container>
		</v-form>
		<v-data-table
			v-if="clients.length > 0"
			:headers="headers"
			:items="clients"
			class="elevation-1"
			:footer-props="{
				itemsPerPageText: 'Nombre de clients par page',
				itemsPerPageOptions: [50, 100, { text: 'Tout', value: -1 }],
			}"
		>
			<template v-slot:[`item.edition`]="{ item }">
				<v-icon
					small
					color="primary"
					class="mr-2"
					@click="editItem(item)"
					>edit</v-icon
				>
				<v-icon small color="error" @click="deleteItem(item)"
					>delete</v-icon
				>
			</template>
			<template slot="footer.page-text" slot-scope="props">
				Lignes {{ props.pageStart }} à {{ props.pageStop }} sur
				{{ props.itemsLength }}
			</template>
		</v-data-table>
		<v-dialog v-model="dialog" max-width="500px">
			<v-card>
				<v-card-title>
					<span class="text-h5">Éditer</span>
				</v-card-title>

				<v-card-text>
					<v-container grid-list-md>
						<v-row wrap>
							<v-col xs12 md6>
								<v-text-field
									v-model="editedItem.name"
									label="Client"
								></v-text-field>
							</v-col>
							<v-col xs12 md6>
								<v-text-field
									v-model="editedItem.mail"
									label="Mail"
								></v-text-field>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="close">Annuler</v-btn>
					<v-btn color="primary" text @click="save"
						>Sauvegarder</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import confirm from "@/components/Confirm.vue";
export default {
	components: { confirm },
	data: function () {
		return {
			snackbarColor: null,
			snackbarMessage: null,
			snackbar: false,
			dialog: false,
			pastEmail: null,
			clients: [],
			client: {
				name: "",
				mail: "",
			},
			headers: [
				{ text: "Client", value: "name" },
				{ text: "Mail", value: "mail" },
				{
					text: "Éditer/Supprimer",
					sortable: false,
					value: "edition",
					align: "center",
				},
			],
			editedItem: {
				name: null,
				mail: null,
			},
		};
	},
	created() {
		this.fetchClients();
	},
	watch: {
		dialog(val) {
			val || this.close();
		},
	},
	methods: {
		fetchClients() {
			this.axios
				.get(process.env.VUE_APP_API_URL + "/api/clients/all", {
					withCredentials: true,
				})
				.then((response) => {
					this.clients = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		addClient() {
			this.axios
				.put(
					process.env.VUE_APP_API_URL + "/api/clients/add",
					this.client,
					{
						withCredentials: true,
					}
				)
				.then(() => {
					this.client.name = "";
					this.client.mail = "";
					this.fetchClients();
				})
				.catch((error) => {
					this.snackbarColor = "error";
					this.snackbarMessage = "Erreur " + error;
					this.snackbar = true;
					console.log(error);
				});
		},
		editItem(item) {
			this.editedIndex = this.clients.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.pastEmail = item.mail;
			this.dialog = true;
		},
		deleteItem(item) {
			const index = this.clients.indexOf(item);
			this.$refs.confirm
				.open("Supprimer", "Êtes-vous sûr(e) ?", "Supprimer", {
					color: "primary",
				})
				.then((confirm) => {
					if (confirm) {
						this.axios
							.delete(
								process.env.VUE_APP_API_URL +
									"/api/clients/delete",
								{
									params: { id: item._id },
									withCredentials: true,
								}
							)
							.then(() => {
								this.clients.splice(index, 1);
							})
							.catch((err) => {
								console.log(err);
							});
					}
				});
		},
		close() {
			this.dialog = false;
			setTimeout(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			}, 1000);
		},

		save() {
			if (this.editedIndex > -1) {
				let mailChanged = this.pastMail != this.editedItem.mail;
				this.axios
					.post(
						process.env.VUE_APP_API_URL + "/api/clients/update",
						{ client: this.editedItem, mailChanged: mailChanged },
						{
							withCredentials: true,
						}
					)
					.then(() => {
						Object.assign(
							this.clients[this.editedIndex],
							this.editedItem
						);
					})
					.catch((err) => {
						console.log(err);
					});
			} else {
				this.axios
					.post(
						process.env.VUE_APP_API_URL + "/api/clients/update",
						this.editedItem,
						{
							withCredentials: true,
						}
					)
					.then(() => {
						this.clients.push(this.editedItem);
					})
					.catch((err) => {
						console.log(err);
					});
			}
			this.close();
		},
	},
};
</script>
