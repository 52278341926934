<template>
	<v-container fluid>
		<v-expansion-panels focusable>
			<v-expansion-panel>
				<v-expansion-panel-header
					class="text-uppercase text-h5 font-weight-regular primary--text"
				>
					Ajouter une pré-réception
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<add-pre-reception
						@fetchContremarques="fetchContremarques()"
					></add-pre-reception>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<v-expansion-panel v-if="user.depot == 'Pau'">
				<v-expansion-panel-header
					class="text-uppercase text-h5 font-weight-regular primary--text"
				>
					Importer des pré-receptions
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<import-pre-receptions-from-file
						ref="importprereceptionsfromfile"
					>
					</import-pre-receptions-from-file>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<v-expansion-panel>
				<v-expansion-panel-header
					class="text-uppercase text-h5 font-weight-regular primary--text"
				>
					Voir les pré-réceptions
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<see-pre-reception
						ref="seeprereception"
					></see-pre-reception>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</v-container>
</template>

<script>
import addPreReception from "@/components/Utilisateur/PreReception/addPreReception.vue";
import seePreReception from "@/components/Utilisateur/PreReception/seePreReception.vue";
import importPreReceptionsFromFile from "@/components/Utilisateur/PreReception/importPreReceptionsFromFile.vue";
export default {
	name: "PreReception",
	components: {
		addPreReception,
		seePreReception,
		importPreReceptionsFromFile,
	},
	data: function () {
		return {
			user: {},
		};
	},
	methods: {
		fetchContremarques() {
			this.$refs.seeprereception.fetchContremarques();
		},
		getUserData: function () {
			let self = this;
			this.axios
				.get(process.env.VUE_APP_API_URL + "/user", {
					withCredentials: true,
				})
				.then((response) => {
					self.$set(this, "user", response.data.user);
				})
				.catch((errors) => {
					console.log(errors);
					router.push("/login");
				});
		},
	},
	created() {
		this.getUserData();
	},
};
</script>
