<template>
	<v-dialog v-model="dialog" @keydown.esc="cancel">
		<v-card>
			<v-dialog v-model="edit" max-width="800px">
				<v-card>
					<v-card-title>
						<span class="text-h5">Éditer</span>
					</v-card-title>

					<v-card-text>
						<v-container grid-list-md>
							<v-row wrap>
								<v-col xs12 sm6 md6>
									<v-autocomplete
										id="fournisseur"
										v-model="editedItem.fournisseur"
										:items="fournisseurs"
										label="Fournisseur"
										single-line
										hide-details
										clearable
										hide-no-data
									></v-autocomplete>
								</v-col>
								<v-col xs12 sm6 md6>
									<v-menu
										v-model="menuEdition1"
										:close-on-content-click="false"
										:nudge-right="40"
										lazy
										transition="scale-transition"
										offset-y
										full-width
										min-width="290px"
									>
										<template v-slot:activator="{ on }">
											<v-text-field
												v-model="outDateFormatted"
												label="Date de sortie prévue"
												prepend-icon="event"
												v-on="on"
											></v-text-field>
										</template>
										<v-date-picker
											v-model="editedItem.outDate"
											@input="menuEdition1 = false"
											locale="fr"
											first-day-of-week="1"
											no-title
										></v-date-picker>
									</v-menu>
								</v-col>
							</v-row>
							<v-row wrap>
								<v-col xs12 sm6 md4>
									<v-autocomplete
										v-model="editedItem.categorie"
										:items="categories"
										label="Catégorie"
										single-line
										hide-details
										clearable
										hide-no-data
									></v-autocomplete>
								</v-col>
								<v-col xs12 sm6 md4>
									<v-text-field
										v-model="editedItem.details"
										label="Détails"
									></v-text-field>
								</v-col>
								<v-col xs12 sm6 md4>
									<v-text-field
										v-model="editedItem.weight"
										label="Poids"
									></v-text-field>
								</v-col>
								<v-col xs12 sm6 md4>
									<v-text-field
										v-model="editedItem.quantity"
										label="Quantité"
									></v-text-field>
								</v-col>
								<v-col xs12 sm6 md4>
									<v-select
										:items="quantityTypes"
										v-model="editedItem.unity"
										label="Unité"
									>
									</v-select>
								</v-col>
							</v-row>
						</v-container>
					</v-card-text>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="primary" text @click="close"
							>Annuler</v-btn
						>
						<v-btn color="primary" text @click="save"
							>Sauvegarder</v-btn
						>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<v-dialog v-model="add" max-width="500px">
				<v-card>
					<v-card-title>
						<span class="text-h5">Ajouter</span>
					</v-card-title>

					<v-card-text>
						<v-container grid-list-md>
							<v-row wrap>
								<v-col xs12 sm6 md6>
									<v-autocomplete
										id="fournisseur"
										v-model="addedItem.fournisseur"
										:items="fournisseurs"
										label="Fournisseur"
										single-line
										hide-details
										clearable
										hide-no-data
									></v-autocomplete>
								</v-col>
								<v-col xs12 sm6 md6>
									<v-menu
										v-model="menuEdition2"
										:close-on-content-click="false"
										:nudge-right="40"
										lazy
										transition="scale-transition"
										offset-y
										full-width
										min-width="290px"
									>
										<template v-slot:activator="{ on }">
											<v-text-field
												v-model="newOutDateFormatted"
												label="Date de sortie prévue"
												prepend-icon="event"
												v-on="on"
											></v-text-field>
										</template>
										<v-date-picker
											v-model="addedItem.outDate"
											@input="menuEdition2 = false"
											locale="fr"
											first-day-of-week="1"
											no-title
										></v-date-picker>
									</v-menu>
								</v-col>
							</v-row>
							<v-row wrap>
								<v-col xs12 sm6 md6>
									<v-autocomplete
										v-model="addedItem.categorie"
										:items="categories"
										label="Catégorie"
										single-line
										hide-details
										clearable
										hide-no-data
									></v-autocomplete>
								</v-col>
								<v-col xs12 sm6 md6>
									<v-text-field
										v-model="addedItem.details"
										label="Détails"
									></v-text-field>
								</v-col>
								<v-col xs12 sm6 md6>
									<v-text-field
										v-model="addedItem.quantity"
										label="Quantité"
									></v-text-field>
								</v-col>
								<v-col xs12 sm6 md6>
									<v-select
										:items="quantityTypes"
										v-model="addedItem.unity"
										label="Unité"
									></v-select>
								</v-col>
							</v-row>
						</v-container>
					</v-card-text>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="primary" text @click="close"
							>Annuler</v-btn
						>
						<v-btn color="primary" text @click="saveNew"
							>Sauvegarder</v-btn
						>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-toolbar dark :color="options.color" dense text>
				<v-toolbar-title class="white--text"
					>Contremarque {{ contremarque.name }}</v-toolbar-title
				>
			</v-toolbar>
			<v-data-table
				:headers="headers"
				:items="contremarque.items"
				:footer-props="{
					itemsPerPageText: 'Nombre de produits par page',
					itemsPerPageOptions: [
						25,
						50,
						100,
						{ text: 'Tout', value: -1 },
					],
				}"
			>
				<template v-slot:[`item.outDate`]="{ item }">
					{{ item.outDate | formatDate }}
				</template>
				<template v-slot:[`item.edition`]="{ item }">
					<v-icon
						small
						color="primary"
						class="mr-2"
						@click="editItem(item)"
						>edit</v-icon
					>
					<v-icon small color="error" @click="deleteItem(item)"
						>delete</v-icon
					>
				</template>
				<template slot="footer.page-text" slot-scope="props">
					Lignes {{ props.pageStart }} à {{ props.pageStop }} sur
					{{ props.itemsLength }}
				</template>
			</v-data-table>
			<div class="text-center pa-2">
				<v-btn rounded outlined color="primary" @click="addItem()"
					>Ajouter un nouveau produit</v-btn
				>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: ["contremarque"],
	data: function () {
		return {
			menuEdition1: false,
			menuEdition2: false,
			outDateFormatted: null,
			newOutDateFormatted: null,
			dialog: false,
			edit: false,
			add: false,
			resolve: null,
			reject: null,
			message: null,
			title: null,
			fournisseurs: [],
			categories: [],
			options: {
				color: "primary",
			},
			quantityTypes: ["colis", "palette", "longueur", "unité"],
			headers: [
				{ text: "Fournisseur", value: "fournisseur" },
				{ text: "Date de sortie prévue", value: "outDate" },
				{ text: "Catégorie", value: "categorie" },
				{ text: "Détails", value: "details" },
				{ text: "Poids", value: "weight" },
				{ text: "Quantité", value: "quantity" },
				{ text: "Unité", value: "unity" },
				{
					text: "Éditer/Supprimer",
					sortable: false,
					value: "edition",
					align: "center",
				},
			],
			editedItem: {
				fournisseur: "",
				outDate: null,
				categorie: "",
				details: "",
				quantity: 0,
				unity: "colis",
				contremarque: this.$props.contremarque._id,
			},
			addedItem: {
				fournisseur: "",
				outDate: null,
				categorie: "",
				details: "",
				quantity: 1,
				unity: "colis",
				status: "prereception",
			},
		};
	},
	filters: {
		formatDate(date) {
			if (!date) return null;
			date = new Date(date).toISOString().substr(0, 10);
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
	},
	watch: {
		edit(val) {
			val || this.close();
		},
		editedItem: {
			handler() {
				if (this.editedItem.outDate != null) {
					this.outDateFormatted = this.formatDate(
						this.editedItem.outDate
					);
					this.editedItem.outDate = new Date(this.editedItem.outDate)
						.toISOString()
						.substr(0, 10);
				}
			},
			deep: true,
		},
		addedItem: {
			handler() {
				if (this.addedItem.outDate != null) {
					this.newOutDateFormatted = this.formatDate(
						this.addedItem.outDate
					);
					this.addedItem.outDate = new Date(this.addedItem.outDate)
						.toISOString()
						.substr(0, 10);
				}
			},
			deep: true,
		},
	},
	created() {
		this.fetchFournisseurs();
		this.fetchCategories();
	},
	methods: {
		fetchFournisseurs() {
			this.axios
				.get(process.env.VUE_APP_API_URL + "/api/fournisseurs/all", {
					withCredentials: true,
				})
				.then((response) => {
					response.data.forEach((item) => {
						this.fournisseurs.push(item.name);
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		fetchCategories() {
			this.axios
				.get(process.env.VUE_APP_API_URL + "/api/categories/all", {
					withCredentials: true,
				})
				.then((response) => {
					response.data.forEach((item) => {
						this.categories.push(item.name);
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		formatDate(date) {
			if (!date) return null;

			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
		editItem(item) {
			this.editedIndex = this.$props.contremarque.items.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.edit = true;
		},
		addItem() {
			this.add = true;
		},
		open() {
			this.dialog = true;
			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			});
		},
		agree() {
			this.resolve(true);
			this.edit = false;
		},
		cancel() {
			this.outDateFormatted = null;
			this.newOutDateFormatted = null;
			this.addedItem = {
				fournisseur: "",
				outDate: null,
				categorie: "",
				details: "",
				quantity: 1,
				unity: "colis",
				status: "prereception",
			};
			this.resolve(false);
			this.dialog = false;
		},
		close() {
			this.outDateFormatted = null;
			this.newOutDateFormatted = null;
			this.addedItem = {
				fournisseur: "",
				outDate: null,
				categorie: "",
				details: "",
				quantity: 1,
				unity: "colis",
				status: "prereception",
			};
			this.edit = false;
			this.add = false;
			setTimeout(() => {
				this.editedIndex = -1;
			}, 300);
		},
		saveNew() {
			this.addedItem.ref = this.$props.contremarque.ref;
			this.addedItem.contremarque = this.$props.contremarque._id;
			this.addedItem.client = this.$props.contremarque.client;
			this.axios
				.put(
					process.env.VUE_APP_API_URL + "/api/items/add",
					this.addedItem,
					{
						withCredentials: true,
					}
				)
				.then((docInserted) => {
					this.axios
						.post(
							process.env.VUE_APP_API_URL +
								"/api/contremarques/update",
							{
								cmq: this.addedItem.contremarque,
								id: docInserted.data._id,
							},
							{
								withCredentials: true,
							}
						)
						.then(() => {
							this.$props.contremarque.items.push(this.addedItem);
							this.addedItem = {
								fournisseur: "",
								outDate: new Date().toISOString().substr(0, 10),
								categorie: "",
								details: "",
								quantity: 1,
								unity: "colis",
								status: "prereception",
							};
							this.add = false;
						});
				});
		},
		save() {
			if (this.editedIndex > -1) {
				Object.assign(
					this.$props.contremarque.items[this.editedIndex],
					this.editedItem
				);
			} else {
				this.$props.contremarque.items.push(this.editedItem);
			}
			this.axios
				.post(
					process.env.VUE_APP_API_URL + "/api/items/update",
					{ item: this.editedItem },
					{
						withCredentials: true,
					}
				)
				.then(() => {
					this.close();
				})
				.catch((error) => {
					this.close();
					console.log(error);
				});
		},
	},
};
</script>
