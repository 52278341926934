<template>
	<div>
		<confirm ref="confirm"></confirm>
		<v-snackbar :color="snackbarColor" v-model="snackbar" :timeout="1500">
			{{ snackbarMessage }}
			<v-btn text color="white" @click="snackbar = false">
				<v-icon center>close</v-icon>
			</v-btn>
		</v-snackbar>
		<v-container fluid>
			<v-subheader class="text-h6"
				>Ajouter un gestionnaire de dépôt</v-subheader
			>
			<v-layout>
				<v-flex xs12 md6>
					<v-text-field
						v-model="user.mail"
						label="Adresse mail"
						autocomplete="off"
						required
					></v-text-field>
				</v-flex>
				<v-flex xs12 md6>
					<v-autocomplete
						v-model="user.depot"
						:items="depots"
						label="Dépôt"
						single-line
						hide-details
						clearable
						hide-no-data
					></v-autocomplete>
				</v-flex>
			</v-layout>
			<div class="text-center mb-4">
				<v-btn
					:loading="isLoading"
					outlined
					color="primary"
					v-on:click="addUser"
					>Ajouter</v-btn
				>
			</div>
		</v-container>

		<v-data-table
			v-if="users.length > 0"
			:headers="headers"
			:items="users"
			hideDefaultFooter
		>
			<template slot="footer.page-text" slot-scope="props">
				Lignes {{ props.pageStart }} à {{ props.pageStop }} sur
				{{ props.itemsLength }}
			</template>
			<template v-slot:[`item.edition`]="{ item }">
				<v-icon small color="error" @click="deleteItem(item)"
					>delete</v-icon
				>
			</template>
		</v-data-table>
	</div>
</template>
<script>
import confirm from "@/components/Confirm.vue";
export default {
	components: { confirm },
	data: function () {
		return {
			snackbarColor: null,
			snackbarMessage: null,
			snackbar: false,
			isLoading: false,
			depots: [],
			users: [],
			user: {
				mail: null,
				depot: null,
			},
			headers: [
				{ text: "Adresse mail", value: "mail" },
				{ text: "Dépot", value: "depot" },
				{
					text: "Supprimer",
					sortable: false,
					value: "edition",
					align: "center",
				},
			],
		};
	},
	created() {
		this.fetchUsers();
		this.fetchDepots();
	},
	methods: {
		fetchDepots() {
			this.axios
				.get(process.env.VUE_APP_API_URL + "/admin/depots/all", {
					withCredentials: true,
				})
				.then((response) => {
					this.depots = [];
					response.data.forEach((item) => {
						this.depots.push(item.name);
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		fetchUsers() {
			this.axios
				.get(process.env.VUE_APP_API_URL + "/admin/users/all", {
					withCredentials: true,
				})
				.then((response) => {
					this.users = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		addUser() {
			this.isLoading = true;
			if (this.depots.indexOf(this.user.depot) == -1) {
				this.snackbarColor = "error";
				this.snackbarMessage =
					"Erreur ! Veuillez saisir un dépôt valide";
				this.snackbar = true;
				this.isLoading = false;
				this.user.depot = null;
				return;
			}
			this.axios
				.put(
					process.env.VUE_APP_API_URL + "/admin/users/add",
					{ user: this.user },
					{
						withCredentials: true,
					}
				)
				.then(() => {
					this.snackbarColor = "success";
					this.snackbarMessage = "Mail envoyé !";
					this.snackbar = true;
					this.isLoading = false;
					this.user.mail = null;
					this.user.depot = null;
					this.fetchUsers();
				})
				.catch((error) => {
					this.snackbarColor = "Error: " + error;
					this.snackbarMessage =
						"Erreur ! Veuillez saisir une adresse mail valide";
					this.snackbar = true;
					this.isLoading = false;
					this.user.mail = null;
					this.user.depot = null;
				});
		},
		deleteItem(item) {
			const index = this.users.indexOf(item);
			this.$refs.confirm
				.open("Supprimer", "Êtes-vous sûr(e) ?", "Supprimer", {
					color: "primary",
				})
				.then((confirm) => {
					if (confirm) {
						this.axios
							.delete(
								process.env.VUE_APP_API_URL +
									"/admin/users/delete",
								{
									params: { id: item._id },
									withCredentials: true,
								}
							)
							.then(() => {
								this.users.splice(index, 1);
							})
							.catch((err) => {
								console.log(err);
							});
					}
				});
		},
	},
};
</script>
