<template>
	<v-container fluid>
		<v-card>
			<v-card-title
				class="text-uppercase text-h5 font-weight-regular primary--text"
			>
				Tableau de bord
				<v-spacer></v-spacer>
				<v-text-field
					v-model="search"
					append-icon="search"
					label="Rechercher un produit"
					single-line
					hide-details
				></v-text-field>
			</v-card-title>
			<v-data-table
				:headers="headers"
				:items="items"
				:search="search"
				:sort-by="['contremarque.inDate', 'contremarque.name']"
				:sort-desc="[true, false]"
				multi-sort
				:footer-props="{
					itemsPerPageText: 'Nombre de produits par page',
					itemsPerPageOptions: [
						25,
						50,
						100,
						{ text: 'Tout', value: -1 },
					],
				}"
			>
				<template v-slot:[`item.contremarque.inDate`]="{ item }">
					{{ item.contremarque.inDate | moment("LL") }}
				</template>
				<template v-slot:[`item.outDate`]="{ item }">
					{{ item.outDate | moment("LL") }}
				</template>
				<template slot="no-data">
					<v-alert :value="true" color="primary" icon="info" outlined
						>Pas de produit dans le dépôt</v-alert
					>
				</template>
				<template slot="footer.page-text" slot-scope="props">
					Lignes {{ props.pageStart }} à {{ props.pageStop }} sur
					{{ props.itemsLength }}
				</template>
				<v-alert
					slot="no-results"
					:value="true"
					color="error"
					icon="warning"
					>La recherche pour "{{ search }}" n'a rien donné.</v-alert
				>
			</v-data-table>
		</v-card>
	</v-container>
</template>

<script>
import router from "@/router";

export default {
	name: "Dashboard",
	components: {},
	data: function () {
		return {
			search: "",
			pagination: {
				sortBy: "depot",
				descending: true,
			},
			items: [],
			headers: [
				{ text: "Dépôt", value: "depot" },
				{ text: "Date de réception", value: "contremarque.inDate" },
				{ text: "Catégorie", value: "categorie" },
				{ text: "Détails", value: "details" },
				{ text: "Poids", value: "weight" },
				{ text: "Fournisseur", value: "fournisseur" },
				{ text: "Client", value: "client" },
				{ text: "Contremarque", value: "contremarque.name" },
				{ text: "Localisation", value: "localisation.name" },
				{ text: "Date de sortie prévue", value: "outDate" },
			],
		};
	},
	created() {
		this.fetchItems();
	},
	methods: {
		fetchItems() {
			this.axios
				.get(process.env.VUE_APP_API_URL + "/admin/items/all", {
					withCredentials: true,
				})
				.then((response) => {
					this.items = response.data;
					this.items.forEach((item) => {
						if (item.localisation == undefined) {
							item.localisation = {};
							item.localisation.name = "En attente de scannage";
						}
						if (
							item.outDate == undefined ||
							item.outDate === null
						) {
							item.outDate = "Non définie";
						}
					});
				})
				.catch((error) => {
					console.log(error);
					router.push("/");
				});
		},
	},
};
</script>
