<template>
	<v-container fluid>
		<v-snackbar :color="snackbarColor" v-model="snackbar" :timeout="2500">
			{{ snackbarMessage }}
			<template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					text
					v-bind="attrs"
					@click="snackbar = false"
				>
					<v-icon center>close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
		<v-card>
			<v-card-title
				class="text-uppercase text-h5 font-weight-regular primary--text"
			>
				Valider une réception
				<v-spacer></v-spacer>
				<v-text-field
					v-model="search"
					append-icon="search"
					label="Rechercher une pré-réception"
					single-line
					hide-details
					clearable
				></v-text-field>
			</v-card-title>
			<valider-prereception
				@fetchContremarques="fetchContremarques()"
				:contremarque="editedContremarque"
				:comptes="comptes"
				ref="gestion"
			></valider-prereception>
			<confirm ref="confirm"></confirm>
			<v-data-table
				v-model="selected"
				:headers="headers"
				:items="contremarques"
				show-select
				item-key="_id"
				:search="search"
				:footer-props="{
					itemsPerPageText: 'Nombre de contremarques par page',
					itemsPerPageOptions: [
						25,
						50,
						100,
						{ text: 'Tout', value: -1 },
					],
				}"
			>
				<template v-slot:[`item.inDate`]="{ item }">
					<v-menu
						v-model="item.menu"
						:close-on-content-click="true"
						:nudge-right="40"
						transition="scale-transition"
						offset-y
						min-width="auto"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								:value="item.inDate | moment('LL')"
								prepend-icon="mdi-calendar"
								readonly
								v-bind="attrs"
								v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
							v-model="item.inDate"
							@input="item.menu = false"
							locale="fr"
							first-day-of-week="1"
							no-title
						></v-date-picker>
					</v-menu>
				</template>
				<template v-slot:[`item.edition`]="{ item }">
					<v-icon
						small
						color="success"
						class="mr-2"
						@click="label(item)"
						>label</v-icon
					>
				</template>
				<template slot="no-data">
					<v-alert :value="true" color="primary" icon="info" outlined
						>Pas de pré-réception</v-alert
					>
				</template>
				<template slot="footer.page-text" slot-scope="props">
					Lignes {{ props.pageStart }} à {{ props.pageStop }} sur
					{{ props.itemsLength }}
				</template>
				<v-alert
					slot="no-results"
					:value="true"
					color="error"
					icon="warning"
					>La recherche pour "{{ search }}" n'a rien donné.</v-alert
				>
			</v-data-table>
			<div v-if="selected.length > 0" class="text-center">
				<v-btn class="mt-3" color="primary" @click="validateSelection"
					>Valider la sélection</v-btn
				>
			</div>
			<br />
		</v-card>
		<v-progress-linear
			v-if="isLoading"
			:indeterminate="true"
		></v-progress-linear>
	</v-container>
</template>

<script>
import confirm from "@/components/Confirm.vue";
import validerPrereception from "@/components/Utilisateur/Reception/validerPrereception.vue";
import * as JsBarcode from "jsbarcode";
import { jsPDF } from "jspdf";

export default {
	name: "seePreReception",
	components: { validerPrereception, confirm },
	data: function () {
		return {
			pagination: {
				sortBy: "name",
			},
			selected: [],
			isLoading: false,
			snackbarColor: null,
			snackbarMessage: null,
			snackbar: false,
			nbrPalettes: null,
			search: "",
			contremarques: [],
			comptes: [],
			contremarqueNameForPalette: null,
			editedContremarque: {
				name: "",
				client: "",
				items: [],
				ref: "",
			},
			headers: [
				{
					text: "Contremarque",
					value: "name",
					align: "center",
				},
				{ text: "Client", value: "client", align: "center" },
				{ text: "N° dossier", value: "ref", align: "center" },
				{ text: "Date de réception", value: "inDate" },
				{
					text: "Imprimer les étiquettes",
					// text: "Valider par produit | Imprimer les étiquettes",
					sortable: false,
					value: "edition",
					align: "center",
				},
			],
		};
	},
	created() {
		this.fetchContremarques();
		this.fetchComptes();
	},
	methods: {
		label(cmq) {
			var doc = new jsPDF({
				orientation: "landscape",
				unit: "in",
				format: [2, 1],
			});
			var centeredText = function (text, y) {
				var textWidth =
					(doc.getStringUnitWidth(text) *
						doc.internal.getFontSize()) /
					doc.internal.scaleFactor;
				var textOffset =
					(doc.internal.pageSize.getWidth() - textWidth) / 2;
				doc.text(textOffset, y, text);
			};
			var n = cmq.items.length;
			cmq.items.forEach((item, index) => {
				var canvas = document.createElement("CANVAS");
				JsBarcode(canvas, item._id, {
					width: 1,
					displayValue: false,
				});
				var img = canvas.toDataURL();

				var width = doc.internal.pageSize.getWidth();
				var height = doc.internal.pageSize.getHeight();

				doc.setFontSize(5);
				doc.addImage(img, "JPEG", 0, 0, width, height * 0.5);
				centeredText(item._id, height * 0.55);
				centeredText("Fournisseur: " + item.fournisseur, height * 0.65);
				centeredText("Client: " + item.client, height * 0.75);
				centeredText(
					"Contremarque: " + item.contremarque.name,
					height * 0.85
				);
				if (index < n - 1) doc.addPage();
			});
			doc.autoPrint();
			window.open(doc.output("bloburl"), "_blank");
		},
		fetchContremarques() {
			this.axios
				.get(
					process.env.VUE_APP_API_URL +
						"/api/contremarques/prereception",
					{
						withCredentials: true,
					}
				)
				.then((response) => {
					this.contremarques = response.data;
					for (let i = 0; i < this.contremarques.length; i++) {
						this.contremarques[i].inDate = new Date()
							.toISOString()
							.substr(0, 10);
						this.contremarques[i].menu = false;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		fetchComptes() {
			this.axios
				.get(process.env.VUE_APP_API_URL + "/api/comptes/all", {
					withCredentials: true,
				})
				.then((response) => {
					this.comptes = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		editContremarque(contremarque) {
			this.editedIndex = this.contremarques.indexOf(contremarque);
			this.editedContremarque = Object.assign({}, contremarque);
			this.$refs.gestion.open();
		},
		editInDate(contremarque) {
			this.editedIndex = this.contremarques.indexOf(contremarque);
			this.editedContremarque = Object.assign({}, contremarque);
			this.$refs.gestiondate.open();
		},
		reception(contremarques) {
			var id_date = {};
			contremarques.forEach((cmq) => {
				id_date[cmq._id] = cmq.inDate;
			});
			this.isLoading = true;
			this.axios
				.post(
					process.env.VUE_APP_API_URL + "/api/contremarques/validate",
					id_date,
					{ withCredentials: true }
				)
				.then(() => {
					this.isLoading = false;
					this.snackbarColor = "success";
					this.snackbarMessage = "Réception validée";
					this.snackbar = true;
					contremarques.forEach((contremarque) => {
						let index = this.contremarques.indexOf(contremarque);
						this.contremarques.splice(index, 1);
					});
				})
				.catch((error) => {
					this.isLoading = false;
					this.snackbarColor = "error";
					this.snackbarMessage =
						"Erreur lors de la validation de la réception";
					this.snackbar = true;
					console.log(error);
				});
		},
		toggleAll() {
			if (this.selected.length) this.selected = [];
			else this.selected = this.contremarques.slice();
		},
		changeSort(column) {
			if (this.pagination.sortBy === column) {
				this.pagination.descending = !this.pagination.descending;
			} else {
				this.pagination.sortBy = column;
				this.pagination.descending = false;
			}
		},
		validateSelection() {
			this.$refs.confirm
				.open(
					"Valider",
					"Êtes-vous sûr(e) de valider la réception ? ",
					"Valider",
					{
						color: "primary",
					}
				)
				.then((confirm) => {
					if (confirm) {
						this.reception(this.selected);
					}
				});
		},
	},
};
</script>
