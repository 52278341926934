<template>
	<div>
		<confirm ref="confirm"></confirm>
		<v-snackbar :color="snackbarColor" v-model="snackbar" :timeout="1500">
			{{ snackbarMessage }}
			<v-btn text color="white" @click="snackbar = false">
				<v-icon center>close</v-icon>
			</v-btn>
		</v-snackbar>
		<v-container fluid>
			<v-subheader class="text-h6">Ajouter un comptable</v-subheader>
			<v-layout align-center justify-center>
				<v-flex xs12 md6>
					<v-text-field
						v-model="comptable.mail"
						label="Adresse mail"
						autocomplete="off"
						required
					>
					</v-text-field>
				</v-flex>
			</v-layout>
			<div class="text-center mb-4">
				<v-btn
					:loading="isLoading"
					outlined
					color="primary"
					v-on:click="addComptable"
					>Ajouter</v-btn
				>
			</div>
		</v-container>

		<v-data-table
			v-if="comptables.length > 0"
			:headers="headers"
			:items="comptables"
			hideDefaultFooter
		>
			<template v-slot:[`item.edition`]="{ item }">
				<td>{{ props.item.mail }}</td>
				<v-icon small color="error" @click="deleteItem(item)"
					>delete</v-icon
				>
			</template>
		</v-data-table>
	</div>
</template>
<script>
import confirm from "@/components/Confirm.vue";
export default {
	components: { confirm },
	data: function () {
		return {
			snackbarColor: null,
			snackbarMessage: null,
			snackbar: false,
			isLoading: false,
			comptables: [],
			comptable: {
				mail: null,
			},
			headers: [
				{ text: "Adresse mail", value: "mail" },
				{
					text: "Supprimer",
					sortable: false,
					value: "edition",
					align: "center",
				},
			],
		};
	},
	created() {
		this.fetchComptables();
	},
	methods: {
		fetchComptables() {
			this.axios
				.get(process.env.VUE_APP_API_URL + "/admin/comptables/all", {
					withCredentials: true,
				})
				.then((response) => {
					this.comptables = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		addComptable() {
			this.isLoading = true;
			this.axios
				.put(
					process.env.VUE_APP_API_URL + "/admin/comptables/add",
					{ comptable: this.comptable },
					{
						withCredentials: true,
					}
				)
				.then(() => {
					this.snackbarColor = "success";
					this.snackbarMessage = "Mail envoyé !";
					this.snackbar = true;
					this.isLoading = false;
					this.comptable.mail = null;
					this.fetchComptables();
				})
				.catch((error) => {
					this.snackbarColor = "Error: " + error;
					this.snackbarMessage =
						"Erreur ! Veuillez saisir une adresse mail valide";
					this.snackbar = true;
					this.isLoading = false;
					this.comptable.mail = null;
				});
		},
		deleteItem(item) {
			const index = this.comptables.indexOf(item);
			this.$refs.confirm
				.open("Supprimer", "Êtes-vous sûr(e) ?", "Supprimer", {
					color: "primary",
				})
				.then((confirm) => {
					if (confirm) {
						this.axios
							.delete(
								process.env.VUE_APP_API_URL +
									"/admin/comptables/delete",
								{
									params: { id: item._id },
									withCredentials: true,
								}
							)
							.then(() => {
								this.comptables.splice(index, 1);
							})
							.catch((err) => {
								console.log(err);
							});
					}
				});
		},
	},
};
</script>
