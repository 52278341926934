var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"timeout":1500},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" "),_c('v-btn',{attrs:{"text":"","color":"white"},on:{"click":function($event){_vm.snackbar = false}}},[_c('v-icon',{attrs:{"center":""}},[_vm._v("close")])],1)],1),_c('v-card',[_c('v-card-title',{staticClass:"text-uppercase text-h5 font-weight-regular primary--text"},[_vm._v(" Sélectionner les produits en préparation "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Rechercher un item","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"show-select":"","item-key":"_key","footer-props":{
				itemsPerPageText: 'Nombre de produits par page',
				itemsPerPageOptions: [
					25,
					50,
					100,
					{ text: 'Tout', value: -1 } ],
			}},scopedSlots:_vm._u([{key:"item.contremarque.inDate",fn:function(ref){
			var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.contremarque.inDate,"LL"))+" ")]}},{key:"item.outDate",fn:function(ref){
			var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.outDate,"LL"))+" ")]}},{key:"item.quantity",fn:function(ref){
			var item = ref.item;
return [_c('v-edit-dialog',{on:{"save":function($event){return _vm.save(item)},"close":_vm.cleanQuantity},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":""},model:{value:(_vm.preparedQuantity),callback:function ($$v) {_vm.preparedQuantity=$$v},expression:"preparedQuantity"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.quantity)+" ")])]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" Lignes "+_vm._s(props.pageStart)+" à "+_vm._s(props.pageStop)+" sur "+_vm._s(props.itemsLength)+" ")]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"no-data"},[_c('v-alert',{attrs:{"value":true,"color":"primary","icon":"info","outlined":""}},[_vm._v("Pas de produit à passer en préparation ")])],1),_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"warning"},slot:"no-results"},[_vm._v("La recherche pour \""+_vm._s(_vm.search)+"\" n'a rien donné.")])],2)],1),(_vm.selected.length > 0)?_c('v-card',[_c('v-card-title',{staticClass:"text-uppercase text-h5 font-weight-regular primary--text"},[_vm._v("Sélection")]),_c('v-data-table',{attrs:{"headers":_vm.selected_headers,"items":_vm.selected,"hideDefaultFooter":""},scopedSlots:_vm._u([{key:"item.contremarque.inDate",fn:function(ref){
			var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.contremarque.inDate,"LL"))+" ")]}},{key:"item.outDate",fn:function(ref){
			var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.outDate,"LL"))+" ")]}},{key:"item.details",fn:function(ref){
			var item = ref.item;
return [(item.removeQuantity)?_c('tr',[_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":""},model:{value:(item.details),callback:function ($$v) {_vm.$set(item, "details", $$v)},expression:"item.details"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.details)+" ")])],1):_c('tr',[_vm._v(" "+_vm._s(item.details)+" ")])]}},{key:"item.contremarque.name",fn:function(ref){
			var item = ref.item;
return [(item.removeQuantity)?_c('tr',[_c('v-edit-dialog',{on:{"save":function($event){return _vm.addToUpdateCmq(item)},"close":_vm.cleanCmqName},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":""},model:{value:(_vm.newContremarqueName),callback:function ($$v) {_vm.newContremarqueName=$$v},expression:"newContremarqueName"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.contremarque.name)+" ")])],1):_c('tr',[_vm._v(" "+_vm._s(item.contremarque.name)+" ")])]}},{key:"item.outReason",fn:function(ref){
			var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.outReasons},model:{value:(item.outReason),callback:function ($$v) {_vm.$set(item, "outReason", $$v)},expression:"item.outReason"}})]}}],null,true)})],1):_vm._e(),(_vm.selected.length > 0)?_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mt-3",attrs:{"loading":_vm.isLoading,"color":"primary","id":"button"},on:{"click":_vm.preparation}},[_vm._v("Valider la préparation")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }