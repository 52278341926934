<template>
	<v-app>
		<transition name="fade" mode="out-in">
			<router-view class="ma-5"></router-view>
		</transition>
	</v-app>
</template>

<script>
export default {
	name: "App",
	components: {},
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
</style>
