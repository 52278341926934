<template>
	<v-dialog v-model="dialog" @keydown.esc="cancel">
		<v-card>
			<v-card-title>
				<span class="text-h5">Éditer</span>
			</v-card-title>
			<v-card-text>
				<v-container grid-list-md>
					<v-layout wrap>
						<v-flex xs12 sm4 md4>
							<v-autocomplete
								v-model="item.categorie"
								:items="categories"
								label="Catégorie"
								single-line
								hide-details
								clearable
								hide-no-data
							></v-autocomplete>
						</v-flex>
						<v-flex xs12 sm4 md4>
							<v-text-field
								v-model="item.details"
								label="Détails"
							></v-text-field>
						</v-flex>
						<v-flex xs12 sm4 md4>
							<v-text-field
								v-model="item.weight"
								label="Poids"
							></v-text-field>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 sm6 md6>
							<v-text-field
								v-model="item.quantity"
								label="Quantité"
							></v-text-field>
						</v-flex>
						<v-flex xs12 sm6 md6>
							<v-select
								:items="quantityTypes"
								v-model="item.unity"
								label="Unité"
							></v-select>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 sm6 md6>
							<v-autocomplete
								v-model="item.fournisseur"
								:items="fournisseurs"
								label="Fournisseur"
								single-line
								hide-details
								clearable
								hide-no-data
							></v-autocomplete>
						</v-flex>
						<v-flex xs12 sm6 md6>
							<v-autocomplete
								v-model="item.client"
								:items="clients"
								label="Client"
								single-line
								hide-details
								clearable
								hide-no-data
							></v-autocomplete>
						</v-flex>
					</v-layout>
					<v-layout wrap align-center justify-center>
						<v-flex xs3 sm3 md3>
							<v-menu
								v-model="menuEdition"
								:close-on-content-click="false"
								:nudge-right="40"
								lazy
								transition="scale-transition"
								offset-y
								min-width="290px"
							>
								<template v-slot:activator="{ on }">
									<v-text-field
										v-model="outDateFormatted"
										label="Date de sortie prévue"
										prepend-icon="event"
										v-on="on"
									></v-text-field>
								</template>
								<v-date-picker
									v-model="item.outDate"
									@input="menuEdition = false"
									locale="fr"
									first-day-of-week="1"
									no-title
								></v-date-picker>
							</v-menu>
						</v-flex>
					</v-layout>
				</v-container>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" text @click="cancel">Annuler</v-btn>
				<v-btn color="primary" text @click="save">Sauvegarder</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: ["item", "fetchItems"],
	data: function () {
		return {
			menuEdition: false,
			outDateFormatted: null,
			dialog: false,
			edit: false,
			add: false,
			resolve: null,
			reject: null,
			message: null,
			title: null,
			fournisseurs: [],
			categories: [],
			clients: [],
			options: {
				color: "primary",
			},
			quantityTypes: ["colis", "palette", "longueur", "unité"],
		};
	},
	filters: {
		formatDate(date) {
			if (!date) return null;
			date = new Date(date).toISOString().substr(0, 10);
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
	},
	watch: {
		edit(val) {
			val || this.close();
		},
		item: {
			handler() {
				if (this.item.outDate != null) {
					this.outDateFormatted = this.formatDate(this.item.outDate);
					this.item.outDate = new Date(this.item.outDate)
						.toISOString()
						.substr(0, 10);
				}
			},
			deep: true,
		},
	},
	created() {
		this.fetchFournisseurs();
		this.fetchCategories();
		this.fetchClients();
	},
	methods: {
		fetchFournisseurs() {
			this.axios
				.get(process.env.VUE_APP_API_URL + "/api/fournisseurs/all", {
					withCredentials: true,
				})
				.then((response) => {
					response.data.forEach((item) => {
						this.fournisseurs.push(item.name);
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		fetchCategories() {
			this.axios
				.get(process.env.VUE_APP_API_URL + "/api/categories/all", {
					withCredentials: true,
				})
				.then((response) => {
					response.data.forEach((item) => {
						this.categories.push(item.name);
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		fetchClients() {
			this.axios
				.get(process.env.VUE_APP_API_URL + "/api/clients/all", {
					withCredentials: true,
				})
				.then((response) => {
					response.data.forEach((item) => {
						this.clients.push(item.name);
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		formatDate(date) {
			if (!date) return null;
			date = new Date(date).toISOString().substr(0, 10);
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
		open() {
			this.dialog = true;
			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			});
		},
		agree() {
			this.resolve(true);
			this.edit = false;
		},
		cancel() {
			this.outDateFormatted = null;
			this.resolve(false);
			this.dialog = false;
		},
		save() {
			this.outDateFormatted = null;
			var newItem = Object.assign({}, this.item);
			var cmqId = newItem.contremarque._id;
			delete newItem.localisation;
			delete newItem.contremarque;
			this.axios
				.post(
					process.env.VUE_APP_API_URL + "/api/items/update",
					{ item: newItem, cmqId: cmqId },
					{
						withCredentials: true,
					}
				)
				.then(() => {
					this.fetchItems();
					this.cancel();
				})
				.catch((error) => {
					this.cancel();
					console.log(error);
				});
		},
	},
};
</script>
