<template>
	<v-container fluid>
		<gestion-pre-reception
			:contremarque="editedContremarque"
			ref="gestion"
		></gestion-pre-reception>
		<confirm ref="confirm"></confirm>
		<v-card-title>
			<v-spacer></v-spacer>
			<v-text-field
				v-model="search"
				append-icon="search"
				label="Rechercher une pré-reception"
				single-line
				hide-details
			>
			</v-text-field>
		</v-card-title>
		<v-data-table
			:headers="headers"
			:items="contremarques"
			:search="search"
			:footer-props="{
				itemsPerPageText: 'Nombre de produits par page',
				itemsPerPageOptions: [25, 50, 100, { text: 'Tout', value: -1 }],
			}"
		>
			<template v-slot:[`item.edition`]="{ item }">
				<td class="justify-center layout px-0">
					<v-icon
						small
						color="primary"
						class="mr-2"
						@click="editContremarque(item)"
						>edit</v-icon
					>
					<v-icon
						small
						color="error"
						@click="deleteContremarque(item)"
						>delete</v-icon
					>
				</td>
			</template>
			<template slot="no-data">
				<v-alert :value="true" color="primary" icon="info" outlined
					>Pas de pré-réception</v-alert
				>
			</template>
			<template slot="footer.page-text" slot-scope="props">
				Lignes {{ props.pageStart }} à {{ props.pageStop }} sur
				{{ props.itemsLength }}
			</template>
			<v-alert
				slot="no-results"
				:value="true"
				color="error"
				icon="warning"
				>La recherche pour "{{ search }}" n'a rien donné.</v-alert
			>
		</v-data-table>
	</v-container>
</template>
<script>
import gestionPreReception from "@/components/Utilisateur/PreReception/gestionPreReception.vue";
import confirm from "@/components/Confirm.vue";

export default {
	name: "seePreReception",
	components: { gestionPreReception, confirm },
	data: function () {
		return {
			search: "",
			contremarques: [],
			editedContremarque: {
				name: "",
				client: "",
				items: [],
				ref: "",
			},
			headers: [
				{ text: "Contremarque", value: "name" },
				{ text: "Client", value: "client" },
				{ text: "N° dossier", value: "ref" },
				{
					text: "Éditer/Supprimer",
					sortable: false,
					value: "edition",
					align: "center",
				},
			],
		};
	},
	created() {
		this.fetchContremarques();
	},
	methods: {
		fetchContremarques() {
			this.axios
				.get(
					process.env.VUE_APP_API_URL +
						"/api/contremarques/prereception",
					{
						withCredentials: true,
					}
				)
				.then((response) => {
					this.contremarques = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		editContremarque(contremarque) {
			this.editedIndex = this.contremarques.indexOf(contremarque);
			this.editedContremarque = Object.assign({}, contremarque);
			this.$refs.gestion.open();
		},
		deleteContremarque(contremarque) {
			const index = this.contremarques.indexOf(contremarque);
			this.$refs.confirm
				.open(
					"Supprimer",
					"Êtes-vous sûr(e) de supprimer la contremarque " +
						contremarque.name +
						" ?",
					"Supprimer",
					{
						color: "primary",
					}
				)
				.then((confirm) => {
					if (confirm) {
						this.contremarques.splice(index, 1);
						this.axios
							.delete(
								process.env.VUE_APP_API_URL +
									"/api/contremarques/delete",
								{
									params: { id: contremarque._id },
									withCredentials: true,
								}
							)
							.catch((error) => {
								console.log(error);
							});
					}
				});
		},
	},
};
</script>
