<template>
	<div>
		<confirm ref="confirm"></confirm>
		<v-container fluid>
			<v-subheader class="text-h6">Ajouter un dépôt</v-subheader>
			<v-layout align-center justify-center>
				<v-flex xs12 md6>
					<v-text-field
						v-model="depot.name"
						label="Nom du dépôt"
						autocomplete="off"
						required
					></v-text-field>
				</v-flex>
			</v-layout>
			<div class="text-center mb-4">
				<v-btn outlined color="primary" v-on:click="addDepot"
					>Ajouter</v-btn
				>
			</div>
		</v-container>

		<v-data-table
			v-if="depots.length > 0"
			:headers="headers"
			:items="depots"
			hideDefaultFooter
		>
			<template v-slot:[`item.delete`]="{ item }">
				<v-icon small color="error" @click="deleteItem(item)"
					>delete</v-icon
				>
			</template>
		</v-data-table>
	</div>
</template>
<script>
import confirm from "@/components/Confirm.vue";
export default {
	components: { confirm },
	data: function () {
		return {
			depots: [],
			depot: {
				name: null,
			},
			headers: [
				{ text: "Nom du dépôt", value: "name" },
				{
					text: "Supprimer",
					sortable: false,
					value: "delete",
					align: "center",
				},
			],
		};
	},
	created() {
		this.fetchDepots();
	},
	methods: {
		fetchDepots() {
			this.axios
				.get(process.env.VUE_APP_API_URL + "/admin/depots/all", {
					withCredentials: true,
				})
				.then((response) => {
					this.depots = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		addDepot() {
			this.axios
				.put(
					process.env.VUE_APP_API_URL + "/admin/depots/add",
					this.depot,
					{
						withCredentials: true,
					}
				)
				.then(() => {
					this.depot.name = null;
					this.$emit("fetchDepots");
					this.fetchDepots();
				})
				.catch((error) => {
					console.log(error);
				});
		},
		deleteItem(item) {
			this.$refs.confirm
				.open("Supprimer", "Êtes-vous sûr(e) ?", "Supprimer", {
					color: "primary",
				})
				.then((confirm) => {
					if (confirm) {
						this.axios
							.delete(
								process.env.VUE_APP_API_URL +
									"/admin/depots/delete",
								{
									params: { id: item._id },
									withCredentials: true,
								}
							)
							.then(() => {
								this.$emit("fetchDepots");
								this.fetchDepots();
							})
							.catch((err) => {
								console.log(err);
							});
					}
				});
		},
	},
};
</script>
