<template>
	<v-container fluid>
		<v-card>
			<v-card-title
				class="text-uppercase text-h5 font-weight-regular primary--text"
			>
				Tableau de bord
				<v-spacer></v-spacer>
				<v-text-field
					v-model="search"
					append-icon="search"
					label="Rechercher un produit"
					single-line
					hide-details
					clearable
				></v-text-field>
			</v-card-title>
			<v-data-table
				:headers="headers"
				:items="items"
				:search="search"
				:sort-by="['contremarque.inDate', 'contremarque.name']"
				:sort-desc="[true, false]"
				multi-sort
				:footer-props="{
					itemsPerPageText: 'Nombre de produits par page',
					itemsPerPageOptions: [
						50,
						100,
						150,
						{ text: 'Tout', value: -1 },
					],
				}"
			>
				<template v-slot:[`item.contremarque.inDate`]="{ item }">
					{{ item.contremarque.inDate | moment("LL") }}
				</template>
				<template v-slot:[`item.outDate`]="{ item }">
					{{ item.outDate | moment("LL") }}
				</template>
				<template slot="no-data">
					<v-alert :value="true" color="primary" icon="info" outlined
						>Pas de produit dans le dépôt</v-alert
					>
				</template>
				<template slot="footer.page-text" slot-scope="props">
					Lignes {{ props.pageStart }} à {{ props.pageStop }} sur
					{{ props.itemsLength }}
				</template>
				<v-alert
					slot="no-results"
					:value="true"
					color="error"
					icon="warning"
					>La recherche pour "{{ search }}" n'a rien donné.</v-alert
				>
			</v-data-table>
		</v-card>
		<div v-if="items.length > 0" class="text-center">
			<v-btn class="mt-3" color="primary">
				<download-excel
					:data="json_data"
					:fields="json_fields"
					name="stock.xls"
					>Exporter sous format Excel
				</download-excel>
			</v-btn>
		</div>
	</v-container>
</template>

<script>
import moment from "moment";
export default {
	name: "Dashboard",
	components: {},
	data: function () {
		return {
			search: "",
			items: [],
			headers: [
				{ text: "Date de réception", value: "contremarque.inDate" },
				{ text: "Catégorie", value: "categorie" },
				{ text: "Détails", value: "details" },
				{ text: "Poids", value: "weight" },
				{ text: "Fournisseur", value: "fournisseur" },
				{ text: "Contremarque", value: "contremarque.name" },
				{ text: "Quantité", value: "quantity" },
				{ text: "Unité", value: "unity" },
				{ text: "Date de sortie prévue", value: "outDate" },
				{ text: "Jours avant facturation", value: "dayBeforeBill" },
			],
			json_fields: {
				"Date de réception": "inDate",
				Catégorie: "categorie",
				Détails: "details",
				Poids: "weight",
				Fournisseur: "fournisseur",
				Client: "client",
				Contremarque: "contremarque.name",
				Quantité: "quantity",
				Unité: "unity",
				Localisation: "localisation",
				"Date de sortie prévue": "outDate",
			},
			json_data: [],
			json_meta: [
				[
					{
						key: "charset",
						value: "utf-8",
					},
				],
			],
		};
	},
	created() {
		this.fetchItems();
	},
	methods: {
		fetchItems() {
			this.axios
				.get(
					process.env.VUE_APP_API_URL + "/client/items/inreception",
					{
						withCredentials: true,
					}
				)
				.then((response) => {
					this.items = response.data;
					var data = [];
					this.items.forEach((item) => {
						// Semaines restantes à facturer
						var outDate = item.contremarque.outDate
							? new Date(item.contremarque.outDate)
							: new Date();
						var inDate = new Date(item.contremarque.inDate);
						outDate.setHours(0, 0, 0, 0);
						inDate.setHours(0, 0, 0, 0);
						var nbOfDays = Math.round(
							(outDate - inDate) / 1000 / 60 / 60 / 24 + 1
						);
						var dayBeforeBill =
							item.contremarque.facturation.daysBeforeBill -
							nbOfDays;
						item["dayBeforeBill"] = Math.max(dayBeforeBill, 0);

						var json_item = JSON.parse(JSON.stringify(item));
						if (item.localisation == undefined) {
							item.localisation = {};
							item.localisation.name = "En attente de scannage";
						}
						if (
							item.outDate == undefined ||
							item.outDate === null
						) {
							item.outDate = "Non définie";
							json_item["outDate"] = "Non définie";
						} else {
							json_item["outDate"] = moment(
								json_item["outDate"]
							).format("DD/MM/YYYY");
						}
						delete json_item["_id"];
						delete json_item["__v"];
						json_item["inDate"] = moment(
							json_item["contremarque"]["inDate"]
						).format("DD/MM/YYYY");

						json_item["localisation"] = item.localisation.name;

						data.push(json_item);
					});
					this.json_data = data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>
