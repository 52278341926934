<template>
	<div>
		<v-snackbar
			:color="snackbarColor"
			v-model="snackbar"
			:timeout="2500"
			top
		>
			{{ snackbarMessage }}
			<v-btn text color="white" @click="snackbar = false">
				<v-icon center>close</v-icon>
			</v-btn>
		</v-snackbar>
		<vue-particles
			color="#0D47A1"
			:particleOpacity="1"
			:particlesNumber="65"
			shapeType="circle"
			:particleSize="2.5"
			linesColor="#0D47A1"
			:linesWidth="1"
			:lineLinked="true"
			:lineOpacity="0.4"
			:linesDistance="150"
			:moveSpeed="2"
			:hoverEffect="false"
			hoverMode="grab"
			:clickEffect="true"
			clickMode="push"
		></vue-particles>
		<v-container fluid fill-height>
			<v-layout align-center justify-center mt-5 pt-5>
				<v-flex xs12 sm8 md4>
					<v-card class="elevation-12">
						<v-toolbar dark color="primary">
							<v-toolbar-title class="text-uppercase">
								<span class="font-weight-light">Isl</span>
								<span>Wms</span>
							</v-toolbar-title>
						</v-toolbar>
						<v-card-text>
							<v-form>
								<v-text-field
									prepend-icon="mdi-warehouse"
									name="depot"
									label="Dépôt"
									type="text"
									autocomplete="off"
									v-model="depot"
									required
								></v-text-field>
								<v-text-field
									prepend-icon="person"
									name="login"
									label="Identifiant"
									type="text"
									autocomplete="off"
									v-model="email"
									required
								></v-text-field>
								<v-text-field
									id="password"
									prepend-icon="lock"
									name="password"
									label="Mot de passe"
									type="password"
									v-model="password"
									required
									v-on:keyup.enter="login"
								></v-text-field>
							</v-form>
						</v-card-text>
						<v-card-actions class="d-flex">
							<v-spacer></v-spacer>
							<v-btn color="primary" v-on:click="login"
								>Connexion</v-btn
							>
							<v-btn
								outlined
								color="primary"
								v-on:click="dialog = true"
								>Mot de passe oublié</v-btn
							>
						</v-card-actions>
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog v-model="dialog" max-width="500px">
			<v-card>
				<v-card-title>
					<span class="text-h5 primary--text"
						>Mot de passe oublié</span
					>
				</v-card-title>

				<v-card-text>
					<v-text-field
						prepend-icon="mdi-warehouse"
						name="depot"
						label="Dépôt"
						type="text"
						autocomplete="off"
						v-model="depot_forgotten"
					></v-text-field>
					<v-text-field
						prepend-icon="mail"
						label="Adresse mail"
						type="mail"
						v-model="mail_forgotten"
					>
					</v-text-field>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="close">Annuler</v-btn>
					<v-btn
						color="primary"
						:loading="isLoading"
						text
						@click="save"
						>Valider</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import router from "../router";
export default {
	name: "Login",
	data: function () {
		return {
			isLoading: false,
			dialog: false,
			snackbarColor: null,
			snackbarMessage: null,
			snackbar: false,
			depot: null,
			depot_forgotten: null,
			mail: null,
			mail_forgotten: null,
			email: "",
			password: "",
		};
	},
	watch: {
		dialog(val) {
			val || this.close();
		},
	},
	methods: {
		close() {
			this.mail = null;
			this.dialog = false;
		},
		save() {
			this.isLoading = true;
			this.axios
				.post(process.env.VUE_APP_API_URL + "/user/forgotten", {
					mail: this.mail_forgotten,
					depot: this.depot_forgotten,
				})
				.then(() => {
					this.isLoading = false;
					this.mail_forgotten = null;
					this.depot_forgotten = null;
					this.snackbarColor = "success";
					this.snackbarMessage =
						"Un mail a été envoyé si l'adresse correspondant à un compte existant";
					this.snackbar = true;
					this.dialog = false;
				})
				.catch((err) => {
					this.isLoading = false;
					this.mail = null;
					this.snackbarColor = "error";
					this.snackbarMessage =
						"Erreur ! Veuillez saisir une adresse mail valide";
					this.snackbar = true;
					console.log(err);
				});
		},
		login: function () {
			if (this.depot == "") {
				this.depot = null;
			}
			let data = {
				depot: this.depot,
				email: this.email,
				password: this.password,
			};
			this.axios
				.post(process.env.VUE_APP_API_URL + "/user/login", data, {
					withCredentials: true,
				})
				.then(() => {
					router.push("/");
				})
				.catch((errors) => {
					this.snackbarColor = "error";
					this.snackbarMessage = "Email ou mot de passe incorrect";
					this.snackbar = true;
					console.log(errors);
				});
		},
	},
};
</script>

<style>
#particles-js {
	position: absolute;
	background-size: cover;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow-y: hidden;
	z-index: 0;
}
</style>
