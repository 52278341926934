var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"timeout":2500},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"center":""}},[_vm._v("close")])],1)]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")]),_c('v-card',[_c('v-card-title',{staticClass:"text-uppercase text-h5 font-weight-regular primary--text"},[_vm._v(" Valider une réception "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Rechercher une pré-réception","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('valider-prereception',{ref:"gestion",attrs:{"contremarque":_vm.editedContremarque,"comptes":_vm.comptes},on:{"fetchContremarques":function($event){return _vm.fetchContremarques()}}}),_c('confirm',{ref:"confirm"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.contremarques,"show-select":"","item-key":"_id","search":_vm.search,"footer-props":{
				itemsPerPageText: 'Nombre de contremarques par page',
				itemsPerPageOptions: [
					25,
					50,
					100,
					{ text: 'Tout', value: -1 } ],
			}},scopedSlots:_vm._u([{key:"item.inDate",fn:function(ref){
			var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
			var on = ref.on;
			var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm._f("moment")(item.inDate,'LL'),"prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(item.menu),callback:function ($$v) {_vm.$set(item, "menu", $$v)},expression:"item.menu"}},[_c('v-date-picker',{attrs:{"locale":"fr","first-day-of-week":"1","no-title":""},on:{"input":function($event){item.menu = false}},model:{value:(item.inDate),callback:function ($$v) {_vm.$set(item, "inDate", $$v)},expression:"item.inDate"}})],1)]}},{key:"item.edition",fn:function(ref){
			var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"success"},on:{"click":function($event){return _vm.label(item)}}},[_vm._v("label")])]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" Lignes "+_vm._s(props.pageStart)+" à "+_vm._s(props.pageStop)+" sur "+_vm._s(props.itemsLength)+" ")]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"no-data"},[_c('v-alert',{attrs:{"value":true,"color":"primary","icon":"info","outlined":""}},[_vm._v("Pas de pré-réception")])],1),_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"warning"},slot:"no-results"},[_vm._v("La recherche pour \""+_vm._s(_vm.search)+"\" n'a rien donné.")])],2),(_vm.selected.length > 0)?_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary"},on:{"click":_vm.validateSelection}},[_vm._v("Valider la sélection")])],1):_vm._e(),_c('br')],1),(_vm.isLoading)?_c('v-progress-linear',{attrs:{"indeterminate":true}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }