var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('gestion-facturation',{ref:"gestion",attrs:{"cmq":_vm.editedContremarque,"depot":_vm.depots[_vm.selectedTab],"updateFunction":_vm.fetchAndProcessItemsByDepot}}),_c('confirm',{ref:"confirm"}),_c('v-card-title',{staticClass:"text-uppercase text-h5 font-weight-regular primary--text"},[_vm._v(" Facturation "),_c('v-spacer'),_c('div',[_c('v-select',{attrs:{"items":_vm.headers,"label":"Choisir colonnes","single-line":"","hide-details":"","multiple":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 4)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.text))])]):_vm._e(),(index === 4)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v("(+"+_vm._s(_vm.selectedHeaders.length - 2)+")")]):_vm._e()]}}]),model:{value:(_vm.selectedHeaders),callback:function ($$v) {_vm.selectedHeaders=$$v},expression:"selectedHeaders"}})],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Rechercher","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-tabs',{attrs:{"background-color":"primary","center-active":"","dark":""},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.depots),function(depot){return _c('v-tab',{key:depot},[_vm._v(_vm._s(depot))])}),1),_c('v-data-table',{attrs:{"loading":_vm.isLoading,"loading-text":"Chargement de la facturation...","headers":_vm.showHeaders,"items":_vm.filteredData,"search":_vm.search,"show-select":"","item-key":"_id","footer-props":{
				itemsPerPageText: 'Nombre de produits par page',
				itemsPerPageOptions: [
					25,
					50,
					100,
					{ text: 'Tout', value: -1 } ],
			}},scopedSlots:_vm._u([{key:"header.client",fn:function(ref){
			var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
			var on = ref.on;
			var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.clientFilter ? 'primary' : ''}},[_vm._v(" mdi-filter ")])],1)]}}],null,true)},[_c('v-card',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-card-text',[_c('v-autocomplete',{attrs:{"items":_vm.clients,"single-line":"","hide-details":"","clearable":"","hide-no-data":""},model:{value:(_vm.clientFilter),callback:function ($$v) {_vm.clientFilter=$$v},expression:"clientFilter"}})],1)],1)],1)]}},{key:"header.facturation.name",fn:function(ref){
			var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
			var on = ref.on;
			var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.compteFilter ? 'primary' : ''}},[_vm._v(" mdi-filter ")])],1)]}}],null,true)},[_c('v-card',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-card-text',[_c('v-autocomplete',{attrs:{"items":_vm.comptes,"single-line":"","hide-details":"","clearable":"","hide-no-data":""},model:{value:(_vm.compteFilter),callback:function ($$v) {_vm.compteFilter=$$v},expression:"compteFilter"}})],1)],1)],1)]}},{key:"header.inDate",fn:function(ref){
			var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
			var on = ref.on;
			var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.outDateFilter ? 'primary' : ''}},[_vm._v(" mdi-filter ")])],1)]}}],null,true)},[_c('v-card',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-date-picker',{attrs:{"range":"","locale":"fr","first-day-of-week":"1","no-title":""},model:{value:(_vm.inDateFilter),callback:function ($$v) {_vm.inDateFilter=$$v},expression:"inDateFilter"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.inDateFilter = null}}},[_vm._v(" Effacer ")])],1)],1)],1)]}},{key:"header.outDate",fn:function(ref){
			var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
			var on = ref.on;
			var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.outDateFilter ? 'primary' : ''}},[_vm._v(" mdi-filter ")])],1)]}}],null,true)},[_c('v-card',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-date-picker',{attrs:{"range":"","locale":"fr","first-day-of-week":"1","no-title":""},model:{value:(_vm.outDateFilter),callback:function ($$v) {_vm.outDateFilter=$$v},expression:"outDateFilter"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.outDateFilter = null}}},[_vm._v(" Effacer ")])],1)],1)],1)]}},{key:"item.inDate",fn:function(ref){
			var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.inDate,"LL"))+" ")]}},{key:"item.billedDays",fn:function(ref){
			var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.billedDays},on:{"update:returnValue":function($event){return _vm.$set(item, "billedDays", $event)},"update:return-value":function($event){return _vm.$set(item, "billedDays", $event)},"save":function($event){return _vm.save(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":""},model:{value:(item.billedDays),callback:function ($$v) {_vm.$set(item, "billedDays", $$v)},expression:"item.billedDays"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.billedDays)+" ")])]}},{key:"item.outDate",fn:function(ref){
			var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.outDate,"LL"))+" ")]}},{key:"item.edition",fn:function(ref){
			var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editCmq(item)}}},[_vm._v("mdi-pencil")])]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" Lignes "+_vm._s(props.pageStart)+" à "+_vm._s(props.pageStop)+" sur "+_vm._s(props.itemsLength)+" ")]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"no-data"},[_c('v-alert',{attrs:{"value":true,"color":"primary","icon":"info","outlined":""}},[_vm._v("Pas de facturation disponible")])],1),_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"warning"},slot:"no-results"},[_vm._v("La recherche pour \""+_vm._s(_vm.search)+"\" n'a rien donné.")])],2)],1),(_vm.selected.length > 0)?_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary"}},[_c('download-excel',{attrs:{"data":_vm.json_data,"fields":_vm.json_fields,"name":"facturation.xls"}},[_vm._v("Exporter sous format Excel")])],1),_c('v-spacer'),_c('v-btn',{staticClass:"mt-3",attrs:{"color":"error"},on:{"click":_vm.deleteSelection}},[_vm._v("Supprimer les "+_vm._s(_vm.selected.length)+" contremarques sélectionnées")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }