<template>
	<v-container fluid>
		<v-card>
			<confirm ref="confirm"></confirm>
			<v-card-title
				class="text-uppercase text-h5 font-weight-regular primary--text"
			>
				Historique des sorties
				<v-spacer></v-spacer>
				<v-text-field
					v-model="search"
					append-icon="search"
					label="Rechercher un item"
					single-line
					hide-details
					clearable
				></v-text-field>
			</v-card-title>
			<v-data-table
				v-model="selected"
				:headers="headers"
				:items="items"
				:search="search"
				show-select
				item-key="_id"
				:footer-props="{
					itemsPerPageText: 'Nombre de produits par page',
					itemsPerPageOptions: [
						25,
						50,
						100,
						{ text: 'Tout', value: -1 },
					],
				}"
			>
				<template v-slot:[`item.contremarque.inDate`]="{ item }">
					{{ item.contremarque.inDate | moment("LL") }}
				</template>
				<template v-slot:[`item.outDate`]="{ item }">
					{{ item.outDate | moment("LL") }}
				</template>
				<template slot="no-data">
					<v-alert :value="true" color="primary" icon="info" outlined
						>Pas de produit dans le dépôt</v-alert
					>
				</template>
				<template slot="footer.page-text" slot-scope="props">
					Lignes {{ props.pageStart }} à {{ props.pageStop }} sur
					{{ props.itemsLength }}
				</template>
				<v-alert
					slot="no-results"
					:value="true"
					color="error"
					icon="warning"
					>La recherche pour "{{ search }}" n'a rien donné.</v-alert
				>
			</v-data-table>
		</v-card>
		<div v-if="selected.length > 0" class="text-center">
			<v-btn class="mt-3" color="primary">
				<download-excel
					:data="json_data"
					:fields="json_fields"
					name="historique.xls"
					>Exporter sous format Excel
				</download-excel>
			</v-btn>
			<!-- <v-spacer></v-spacer>
				<v-btn class="mt-3" color="error" @click="deleteSelection"
					>Supprimer la sélection</v-btn
				> -->
		</div>
	</v-container>
</template>

<script>
import moment from "moment";
import confirm from "@/components/Confirm.vue";
export default {
	name: "History",
	components: { confirm },
	data: () => ({
		search: "",
		pagination: {
			sortBy: "outDate",
		},
		items: [],
		selected: [],
		headers: [
			{
				text: "Date de réception",
				value: "contremarque.inDate",
				align: "center",
			},
			{ text: "Date de sortie", value: "outDate", align: "center" },
			{ text: "Catégorie", value: "categorie", align: "center" },
			{ text: "Détails", value: "details", align: "center" },
			{ text: "Poids", value: "weight", align: "center" },
			{ text: "Fournisseur", value: "fournisseur", align: "center" },
			{ text: "Client", value: "client", align: "center" },
			{
				text: "Contremarque",
				value: "contremarque.name",
				align: "center",
			},
			{ text: "Motif de sortie", value: "outReason", align: "center" },
		],
		json_fields: {
			"Date de réception": "inDate",
			"Date de sortie": "outDate",
			Catégorie: "categorie",
			Détails: "details",
			Poids: "weight",
			Fournisseur: "fournisseur",
			Client: "client",
			Contremarque: "contremarque.name",
			Quantité: "quantity",
			Unité: "unity",
			"Motif de sortie": "outReason",
		},
		json_data: [],
		json_meta: [
			[
				{
					key: "charset",
					value: "utf-8",
				},
			],
		],
	}),
	created() {
		this.fetchItems();
	},
	watch: {
		selected() {
			this.createJsonData();
		},
	},
	methods: {
		createJsonData() {
			var data = [];
			for (let i = 0; i < this.selected.length; i++) {
				let item = JSON.parse(JSON.stringify(this.selected[i]));
				delete item["_id"];
				delete item["__v"];
				item["outDate"] = moment(item["outDate"]).format("DD/MM/YYYY");
				item["inDate"] = moment(item["contremarque"]["inDate"]).format(
					"DD/MM/YYYY"
				);
				data.push(item);
			}
			this.json_data = data;
		},
		fetchItems() {
			this.axios
				.get(process.env.VUE_APP_API_URL + "/api/items/history", {
					withCredentials: true,
				})
				.then((response) => {
					this.items = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		// deleteSelection() {
		// 	this.$refs.confirm
		// 		.open("Supprimer", "Êtes-vous sûr(e) ?", "Supprimer", {
		// 			color: "primary",
		// 		})
		// 		.then((confirm) => {
		// 			if (confirm) {
		// 				let sizeBatch = 1;
		// 				let nBatches = Math.floor(
		// 					this.selected.length / sizeBatch - 1
		// 				);
		// 				var promises = [];
		// 				for (let i = 0; i < nBatches + 1; i++) {
		// 					promises.push(
		// 						this.axios.delete(
		// 							process.env.VUE_APP_API_URL +
		// 								"/api/items/delete",
		// 							{
		// 								params: {
		// 									items: this.selected.slice(
		// 										i * sizeBatch,
		// 										Math.min(
		// 											(i + 1) * sizeBatch,
		// 											this.selected.length
		// 										)
		// 									),
		// 								},
		// 								withCredentials: true,
		// 							}
		// 						)
		// 					);
		// 				}
		// 				Promise.all(promises)
		// 					.then(() => {
		// 						this.selected = [];
		// 						this.fetchItems();
		// 					})
		// 					.catch((err) => {
		// 						console.log(err);
		// 					});
		// 			}
		// 		});
		// },
	},
};
</script>
