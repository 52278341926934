<template>
	<v-container fluid>
		<v-snackbar :color="snackbarColor" v-model="snackbar" :timeout="1500">
			{{ snackbarMessage }}
			<v-btn text color="white" @click="snackbar = false">
				<v-icon center>close</v-icon>
			</v-btn>
		</v-snackbar>
		<v-card>
			<v-card-title
				class="text-uppercase text-h5 font-weight-regular primary--text justify-center"
				>Scanner le code-barres produit</v-card-title
			>
			<v-row>
				<v-col>
					<v-text-field
						@input="changeField1"
						class="centered-input font-weight-light text-h4"
						single-line
						id="tag1"
						v-model="tag1"
					></v-text-field>
				</v-col>
			</v-row>

			<v-card-title
				class="text-uppercase text-h5 font-weight-regular primary--text justify-center"
				>Scanner le code-barres emplacement</v-card-title
			>
			<v-row>
				<v-col>
					<v-text-field
						@input="changeField2"
						class="centered-input font-weight-light text-h4"
						single-line
						id="tag2"
						v-model="tag2"
					></v-text-field>
				</v-col>
			</v-row>
			<div class="text-center">
				<v-btn
					@keypress="associate"
					class="md-3"
					color="primary"
					id="button"
					>Valider l'association
				</v-btn>
			</div>
		</v-card>
	</v-container>
</template>

<script>
export default {
	components: {},
	data: function () {
		return {
			snackbarColor: null,
			snackbarMessage: null,
			snackbar: false,
			tag1: null,
			tag2: null,
		};
	},
	methods: {
		changeField1() {
			setTimeout(function () {
				var tag = document.getElementById("tag2");
				tag.focus();
				tag.select();
			}, 300);
		},
		changeField2() {
			setTimeout(function () {
				var tag = document.getElementById("button");
				tag.focus();
				tag.select();
			}, 300);
		},
		associate() {
			this.axios
				.post(
					process.env.VUE_APP_API_URL + "/api/items/localisation",
					{
						_id: this.tag1,
						localisation: this.tag2,
					},
					{ withCredentials: true }
				)
				.then(() => {
					this.tag1 = null;
					this.tag2 = null;
					this.snackbarColor = "success";
					this.snackbarMessage = "Association effectuée";
					this.snackbar = true;
					var tag = document.getElementById("tag1");
					tag.focus();
					tag.select();
				})
				.catch((err) => {
					console.log(err);
					this.tag1 = null;
					this.tag2 = null;
					this.snackbarColor = "error";
					this.snackbarMessage = "Erreur lors de l'association";
					this.snackbar = true;
					var tag = document.getElementById("tag1");
					tag.focus();
					tag.select();
				});
		},
	},
	mounted() {
		var tag = document.getElementById("tag1");
		tag.focus();
		tag.select();
	},
};
</script>
<style>
.centered-input input {
	text-align: center;
}
</style>
