<template>
	<div class="main">
		<navbar
			v-if="user.category == 0"
			@selection="changeSelected"
			:selected="selected"
			:user="user"
		/>
		<v-main v-if="user.category == 0">
			<Dashboard v-if="selected == 'dashboard'" />
			<prereception v-if="selected == 'prereception'" />
			<localisation v-if="selected == 'localisation'" />
			<preparation v-if="selected == 'preparation'" />
			<reception v-if="selected == 'reception'" />
			<gestion v-if="selected == 'gestion'" />
			<history v-if="selected == 'history'" />
		</v-main>
		<navbar-client
			v-if="user.category == -1"
			@selection="changeSelected"
			:selected="selected"
			:user="user"
		/>
		<v-main v-if="user.category == -1">
			<Dashboard-client />
		</v-main>
		<navbar-admin
			v-if="user.category == 1"
			@selection="changeSelected"
			:selected="selected"
			:user="user"
		/>
		<v-main v-if="user.category == 1">
			<Dashboard-admin v-if="selected == 'dashboard'" />
			<facturation v-if="selected == 'facturation'" />
			<comptes-facturation v-if="selected == 'comptesFacturation'" />
			<gestion-admin v-if="selected == 'gestion'" />
		</v-main>
	</div>
</template>
<script>
import router from "../router";
import Navbar from "@/components/Utilisateur/Navbar.vue";
import NavbarAdmin from "@/components/Administrateur/Navbar.vue";
import NavbarClient from "@/components/Client/Navbar.vue";
import Dashboard from "@/components/Utilisateur/Dashboard.vue";
import DashboardClient from "@/components/Client/Dashboard.vue";
import DashboardAdmin from "@/components/Administrateur/Dashboard.vue";
import Prereception from "@/components/Utilisateur/PreReception.vue";
import Reception from "@/components/Utilisateur/Reception.vue";
import Localisation from "@/components/Utilisateur/Localisation.vue";
import Preparation from "@/components/Utilisateur/Preparation.vue";
import History from "@/components/Utilisateur/History.vue";
import Gestion from "@/components/Utilisateur/Gestion.vue";
import GestionAdmin from "@/components/Administrateur/Gestion.vue";
import Facturation from "@/components/Administrateur/Facturation.vue";
import comptesFacturation from "@/components/Administrateur/comptesFacturation.vue";
import ComptesFacturation from "../components/Administrateur/comptesFacturation.vue";
export default {
	components: {
		Navbar,
		NavbarAdmin,
		NavbarClient,
		Dashboard,
		DashboardClient,
		DashboardAdmin,
		Prereception,
		Reception,
		Gestion,
		GestionAdmin,
		Localisation,
		Preparation,
		History,
		Facturation,
		comptesFacturation,
	},
	data: function () {
		return {
			user: {},
			selected: "dashboard",
		};
	},
	methods: {
		changeSelected(value) {
			this.selected = value;
		},
		getUserData: function () {
			let self = this;
			this.axios
				.get(process.env.VUE_APP_API_URL + "/user", {
					withCredentials: true,
				})
				.then((response) => {
					self.$set(this, "user", response.data.user);
				})
				.catch((errors) => {
					console.log(errors);
					router.push("/login");
				});
		},
	},
	created() {
		this.getUserData();
	},
};
</script>
