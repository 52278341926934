<template>
	<div>
		<confirm ref="confirm"></confirm>
		<v-container fluid>
			<v-subheader class="text-h6">Ajouter une catégorie</v-subheader>
			<v-row align-center justify-center>
				<v-col xs12 md6>
					<v-text-field
						v-model="categorie.name"
						label="Nom"
						autocomplete="off"
						required
					></v-text-field>
				</v-col>
			</v-row>
			<div class="text-center mb-4">
				<v-btn outlined color="primary" v-on:click="addCategorie"
					>Ajouter</v-btn
				>
			</div>
		</v-container>

		<v-data-table
			v-if="categories.length > 0"
			:headers="headers"
			:items="categories"
			class="elevation-1"
			:footer-props="{
				itemsPerPageText: 'Nombre de catégories par page',
				itemsPerPageOptions: [25, 50, 100, { text: 'Tout', value: -1 }],
			}"
		>
			<template v-slot:[`item.edition`]="{ item }">
				<v-icon
					small
					color="primary"
					class="mr-2"
					@click="editItem(item)"
					>edit</v-icon
				>
				<v-icon small color="error" @click="deleteItem(item)"
					>delete</v-icon
				>
			</template>
			<template slot="footer.page-text" slot-scope="props">
				Lignes {{ props.pageStart }} à {{ props.pageStop }} sur
				{{ props.itemsLength }}
			</template>
		</v-data-table>
		<v-dialog v-model="dialog" max-width="500px">
			<v-card>
				<v-card-title>
					<span class="text-h5">Éditer</span>
				</v-card-title>

				<v-card-text>
					<v-container grid-list-md>
						<v-row wrap align-center justify-center>
							<v-col>
								<v-text-field
									v-model="editedItem.name"
									label="Categorie"
								></v-text-field>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="close"
						>Annuler</v-btn
					>
					<v-btn color="blue darken-1" text @click="save"
						>Sauvegarder</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import confirm from "@/components/Confirm.vue";
export default {
	components: { confirm },
	data: function () {
		return {
			dialog: false,
			categories: [],
			categorie: {
				name: null,
			},
			headers: [
				{ text: "Catégorie", value: "name" },
				{
					text: "Éditer/Supprimer",
					sortable: false,
					value: "edition",
					align: "center",
				},
			],
			editedItem: {
				categorie: null,
			},
		};
	},
	created() {
		this.fetchCategories();
	},
	watch: {
		dialog(val) {
			val || this.close();
		},
	},
	methods: {
		fetchCategories() {
			this.axios
				.get(process.env.VUE_APP_API_URL + "/api/categories/all", {
					withCredentials: true,
				})
				.then((response) => {
					this.categories = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		addCategorie() {
			this.axios
				.put(
					process.env.VUE_APP_API_URL + "/api/categories/add",
					this.categorie,
					{ withCredentials: true }
				)
				.then(() => {
					this.fetchCategories();
				})
				.catch((error) => {
					console.log(error);
				});
		},
		editItem(item) {
			this.editedIndex = this.categories.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},
		deleteItem(item) {
			const index = this.categories.indexOf(item);
			this.$refs.confirm
				.open("Supprimer", "Êtes-vous sûr(e) ?", "Supprimer", {
					color: "primary",
				})
				.then((confirm) => {
					if (confirm) {
						this.axios
							.delete(
								process.env.VUE_APP_API_URL +
									"/api/categories/delete",
								{
									params: { id: item._id },
									withCredentials: true,
								}
							)
							.then(() => {
								this.categories.splice(index, 1);
							})
							.catch((err) => {
								console.log(err);
							});
					}
				});
		},
		close() {
			this.dialog = false;
			setTimeout(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			}, 300);
		},

		save() {
			if (this.editedIndex > -1) {
				this.axios
					.post(
						process.env.VUE_APP_API_URL + "/api/categories/update",
						this.editedItem,
						{
							withCredentials: true,
						}
					)
					.then(() => {
						Object.assign(
							this.categories[this.editedIndex],
							this.editedItem
						);
					})
					.catch((err) => {
						console.log(err);
					});
			} else {
				this.axios
					.post(
						process.env.VUE_APP_API_URL + "/api/categories/update",
						this.editedItem,
						{
							withCredentials: true,
						}
					)
					.then(() => {
						this.categories.push(this.editedItem);
					})
					.catch((err) => {
						console.log(err);
					});
			}
			this.close();
		},
	},
};
</script>
