<template>
	<v-container fluid>
		<v-card>
			<gestion-facturation
				:cmq="editedContremarque"
				:depot="depots[selectedTab]"
				:updateFunction="fetchAndProcessItemsByDepot"
				ref="gestion"
			></gestion-facturation>
			<confirm ref="confirm"></confirm>
			<v-card-title
				class="text-uppercase text-h5 font-weight-regular primary--text"
			>
				Facturation
				<v-spacer></v-spacer>
				<div>
					<v-select
						v-model="selectedHeaders"
						:items="headers"
						label="Choisir colonnes"
						single-line
						hide-details
						multiple
						return-object
					>
						<template v-slot:selection="{ item, index }">
							<v-chip v-if="index < 4">
								<span>{{ item.text }}</span>
							</v-chip>
							<span
								v-if="index === 4"
								class="grey--text text-caption"
								>(+{{ selectedHeaders.length - 2 }})</span
							>
						</template>
					</v-select>
				</div>
				<v-spacer></v-spacer>
				<v-text-field
					v-model="search"
					append-icon="search"
					label="Rechercher"
					single-line
					hide-details
					clearable
				></v-text-field>
			</v-card-title>
			<v-tabs
				background-color="primary"
				center-active
				dark
				v-model="selectedTab"
			>
				<v-tab v-for="depot in depots" :key="depot">{{ depot }}</v-tab>
			</v-tabs>
			<v-data-table
				v-model="selected"
				:loading="isLoading"
				loading-text="Chargement de la facturation..."
				:headers="showHeaders"
				:items="filteredData"
				:search="search"
				show-select
				item-key="_id"
				:footer-props="{
					itemsPerPageText: 'Nombre de produits par page',
					itemsPerPageOptions: [
						25,
						50,
						100,
						{ text: 'Tout', value: -1 },
					],
				}"
			>
				<template v-slot:[`header.client`]="{ header }">
					{{ header.text }}
					<v-menu offset-y :close-on-content-click="false">
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon v-bind="attrs" v-on="on">
								<v-icon
									small
									:color="clientFilter ? 'primary' : ''"
								>
									mdi-filter
								</v-icon>
							</v-btn>
						</template>
						<v-card style="background-color: white; width: 280px">
							<v-card-text>
								<v-autocomplete
									v-model="clientFilter"
									:items="clients"
									single-line
									hide-details
									clearable
									hide-no-data
								></v-autocomplete>
							</v-card-text>
						</v-card>
					</v-menu>
				</template>
				<template v-slot:[`header.facturation.name`]="{ header }">
					{{ header.text }}
					<v-menu offset-y :close-on-content-click="false">
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon v-bind="attrs" v-on="on">
								<v-icon
									small
									:color="compteFilter ? 'primary' : ''"
								>
									mdi-filter
								</v-icon>
							</v-btn>
						</template>
						<v-card style="background-color: white; width: 280px">
							<v-card-text>
								<v-autocomplete
									v-model="compteFilter"
									:items="comptes"
									single-line
									hide-details
									clearable
									hide-no-data
								></v-autocomplete>
							</v-card-text>
						</v-card>
					</v-menu>
				</template>
				<template v-slot:[`header.inDate`]="{ header }">
					{{ header.text }}
					<v-menu offset-y :close-on-content-click="false">
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon v-bind="attrs" v-on="on">
								<v-icon
									small
									:color="outDateFilter ? 'primary' : ''"
								>
									mdi-filter
								</v-icon>
							</v-btn>
						</template>
						<v-card style="background-color: white; width: 280px">
							<v-date-picker
								v-model="inDateFilter"
								range
								locale="fr"
								first-day-of-week="1"
								no-title
							>
								<v-spacer></v-spacer>
								<v-btn
									text
									color="primary"
									@click="inDateFilter = null"
								>
									Effacer
								</v-btn>
							</v-date-picker>
						</v-card>
					</v-menu>
				</template>
				<template v-slot:[`header.outDate`]="{ header }">
					{{ header.text }}
					<v-menu offset-y :close-on-content-click="false">
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon v-bind="attrs" v-on="on">
								<v-icon
									small
									:color="outDateFilter ? 'primary' : ''"
								>
									mdi-filter
								</v-icon>
							</v-btn>
						</template>
						<v-card style="background-color: white; width: 280px">
							<v-date-picker
								v-model="outDateFilter"
								range
								locale="fr"
								first-day-of-week="1"
								no-title
							>
								<v-spacer></v-spacer>
								<v-btn
									text
									color="primary"
									@click="outDateFilter = null"
								>
									Effacer
								</v-btn>
							</v-date-picker>
						</v-card>
					</v-menu>
				</template>
				<template v-slot:[`item.inDate`]="{ item }">
					{{ item.inDate | moment("LL") }}
				</template>
				<template v-slot:[`item.billedDays`]="{ item }">
					<v-edit-dialog
						:return-value.sync="item.billedDays"
						@save="save(item)"
					>
						{{ item.billedDays }}
						<template v-slot:input>
							<v-text-field
								v-model="item.billedDays"
								label="Edit"
								single-line
							></v-text-field>
						</template>
					</v-edit-dialog>
				</template>
				<template v-slot:[`item.outDate`]="{ item }">
					{{ item.outDate | moment("LL") }}
				</template>
				<template v-slot:[`item.edition`]="{ item }">
					<v-icon small color="primary" @click="editCmq(item)"
						>mdi-pencil</v-icon
					>
				</template>
				<template slot="no-data">
					<v-alert :value="true" color="primary" icon="info" outlined
						>Pas de facturation disponible</v-alert
					>
				</template>
				<template slot="footer.page-text" slot-scope="props">
					Lignes {{ props.pageStart }} à {{ props.pageStop }} sur
					{{ props.itemsLength }}
				</template>
				<v-alert
					slot="no-results"
					:value="true"
					color="error"
					icon="warning"
					>La recherche pour "{{ search }}" n'a rien donné.</v-alert
				>
			</v-data-table>
		</v-card>
		<div v-if="selected.length > 0" class="text-center">
			<v-btn class="mt-3" color="primary">
				<download-excel
					:data="json_data"
					:fields="json_fields"
					name="facturation.xls"
					>Exporter sous format Excel</download-excel
				>
			</v-btn>
			<v-spacer></v-spacer>
			<v-btn class="mt-3" color="error" @click="deleteSelection"
				>Supprimer les {{ selected.length }} contremarques
				sélectionnées</v-btn
			>
		</div>
	</v-container>
</template>

<script>
import gestionFacturation from "@/components/Administrateur/gestionFacturation.vue";
import confirm from "@/components/Confirm.vue";
import moment from "moment";
export default {
	name: "Facturation",
	components: { confirm, gestionFacturation },
	data: function () {
		return {
			inDateFilter: null,
			outDateFilter: null,
			isLoading: true,
			search: "",
			pagination: {
				sortBy: "inDate",
				descending: true,
			},
			selected: [],
			cmqs: [],
			depots: [],
			cmqs_by_depot: {},
			comptes: [],
			clients: [],
			compte_ids: [],
			selectedTab: 0,
			nameFilter: "",
			compteFilter: "",
			clientFilter: "",
			editedContremarque: {},
			selectedHeaders: [],
			headers: [
				{ text: "Réception", value: "inDate", align: "center" },
				{ text: "Contremarque", value: "name", align: "center" },
				{ text: "Produits", value: "strProducts", align: "center" },
				{ text: "Détails", value: "strDetails", align: "center" },
				{ text: "Poids", value: "strWeights", align: "center" },
				{ text: "Palettes", value: "nbrPalettes", align: "center" },
				{ text: "Client", value: "client", align: "center" },
				{ text: "Compte", value: "facturation.name", align: "center" },
				{ text: "Sortie", value: "outDate", align: "center" },
				{
					text: "Motifs de sortie",
					value: "strOutReasons",
					align: "center",
				},
				{
					text: "Prix/jour",
					value: "facturation.price",
					align: "center",
				},
				{
					text: "Jours avant facturation",
					value: "facturation.daysBeforeBill",
					align: "center",
				},
				{
					text: "Jours à facturer",
					value: "daysToBill",
					align: "center",
				},
				{
					text: "Jours facturées",
					value: "billedDays",
					align: "center",
				},
				{ text: "Total à facturer", value: "toBill", align: "center" },
				{
					text: "Editer",
					sortable: false,
					value: "edition",
					align: "center",
				},
			],
			json_fields: {
				"Date de réception": "inDate",
				Contremarque: "name",
				Détails: "strDetails",
				Poids: "strWeights",
				Produits: "strProducts",
				Palettes: "nbrPalettes",
				Compte: "facturation.name",
				"Date de sortie": "outDate",
				"Motif de sortie": "strOutReasons",
				"Prix au jour": "facturation.price",
				"Jours avant facturation": "facturation.daysBeforeBill",
				"Jours à facturer": "daysToBill",
				"Jours facturées": "billedDays",
				"Total à facturer": "toBill",
			},
			json_data: [],
			json_meta: [
				[
					{
						key: "charset",
						value: "utf-8",
					},
				],
			],
		};
	},
	mounted() {
		this.init();
	},
	watch: {
		selectedTab() {
			this.fetchAndProcessItemsByDepot(this.depots[this.selectedTab]);
		},
		selected() {
			this.createJsonData();
		},
	},
	created() {
		this.selectedHeaders = this.headers;
	},
	computed: {
		showHeaders() {
			return this.headers.filter((s) => this.selectedHeaders.includes(s));
		},
		filteredData() {
			var conditions = [];
			var cmqs = this.cmqs.slice(0);
			if (this.nameFilter) {
				conditions.push(this.filterName);
			}
			if (this.compteFilter) {
				conditions.push(this.filterCompte);
			}
			if (this.clientFilter) {
				conditions.push(this.filterClient);
			}
			if (this.outDateFilter) {
				conditions.push(this.filterOutDate);
			}
			if (this.inDateFilter) {
				conditions.push(this.filterInDate);
			}
			if (conditions.length > 0) {
				return cmqs.filter((cmq) => {
					return conditions.every((condition) => {
						return condition(cmq);
					});
				});
			}
			for (let i = 0; i < cmqs.length; i++) {
				var products = [];
				var details = [];
				var weights = [];
				var reasons = [];

				for (let j = 0; j < cmqs[i].items.length; j++) {
					products.push(cmqs[i].items[j].categorie);
					details.push(cmqs[i].items[j].details);
					weights.push(cmqs[i].items[j].weight);
					reasons.push(cmqs[i].items[j].outReason);
				}

				// Str details
				cmqs[i]["strDetails"] = this.formatList(details);

				// Str weights
				cmqs[i]["strWeights"] = this.formatList(weights);

				// Str products
				cmqs[i]["strProducts"] = this.formatList(products);

				// Str outReasons
				cmqs[i]["strOutReasons"] = this.formatList(reasons);

				// Nbr de palettes
				var nbrPalettes = 0;
				if (cmqs[i].facturation.palette == true) {
					nbrPalettes = cmqs[i].nbrPalettes;
				} else {
					nbrPalettes = 1;
				}
				cmqs[i]["nbrPalettes"] = nbrPalettes;

				// Jours restants à facturer
				var outDate = cmqs[i].outDate
					? new Date(cmqs[i].outDate)
					: new Date();
				var inDate = new Date(cmqs[i].inDate);
				outDate.setHours(0, 0, 0, 0);
				inDate.setHours(0, 0, 0, 0);
				var nbOfDays = Math.round(
					(outDate - inDate) / 1000 / 60 / 60 / 24 + 1
				);
				var daysToBill =
					Math.max(nbOfDays - cmqs[i].facturation.daysBeforeBill, 0) -
					cmqs[i].billedDays;
				cmqs[i]["daysToBill"] = daysToBill;
				// À facturer
				var toBill = parseFloat(
					(daysToBill * cmqs[i].facturation.price).toFixed(4)
				);
				cmqs[i]["toBill"] = toBill;
			}
			return cmqs;
		},
		dateRangeText() {
			var date1 = this.formatDate(this.outDateFilter[0]);
			var date2 = this.formatDate(this.outDateFilter[1]);
			return date1 + " ~ " + date2;
		},
	},
	filters: {
		formatDate(date) {
			if (!date) return null;

			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
	},
	methods: {
		filterName(item) {
			return item.name
				.toLowerCase()
				.includes(this.nameFilter.toLowerCase());
		},
		filterCompte(item) {
			return item.facturation.name
				.toLowerCase()
				.includes(this.compteFilter.toLowerCase());
		},
		filterClient(item) {
			return item.client
				.toLowerCase()
				.includes(this.clientFilter.toLowerCase());
		},
		filterOutDate(item) {
			if (item.outDate == null) {
				return true;
			}
			var startDate = new Date(this.outDateFilter[0]);
			var outDate = new Date(item.outDate);
			if (this.outDateFilter[1] == null) {
				return startDate <= outDate;
			} else {
				var endDate = new Date(this.outDateFilter[1]);
				return startDate <= outDate && outDate <= endDate;
			}
		},
		filterInDate(item) {
			if (item.inDate == null) {
				return true;
			}
			var startDate = new Date(this.inDateFilter[0]);
			var inDate = new Date(item.inDate);
			if (this.inDateFilter[1] == null) {
				return startDate <= inDate;
			} else {
				var endDate = new Date(this.inDateFilter[1]);
				return startDate <= inDate && inDate <= endDate;
			}
		},
		formatList(strings) {
			// Check if all elements in the list are null, undefined, or empty string
			const allNullOrUndefined = strings.every(
				(str) => str === null || str === undefined || str === ""
			);

			// If all elements are null, undefined, or empty string, return "N/A"
			if (allNullOrUndefined) {
				return "N/A";
			}

			// Check if all elements are equal
			const allEqual = strings.every(
				(str, index, arr) => index === 0 || str === arr[index - 1]
			);

			// If all elements are equal, return just one occurrence
			if (allEqual) {
				return strings[0];
			}

			// Replace null, undefined, and empty string elements with "N/A" and join with hyphen
			const formattedList = strings
				.map((str) =>
					str === null || str === undefined || str === ""
						? "N/A"
						: str
				)
				.join("-");

			return formattedList;
		},
		save(cmq) {
			this.axios
				.put(
					process.env.VUE_APP_API_URL +
						"/admin/contremarques/updatebill",
					{ id: cmq._id, billedDays: cmq.billedDays },
					{
						withCredentials: true,
					}
				)
				.catch((error) => {
					console.log(error);
				});
		},
		init() {
			this.axios
				.get(process.env.VUE_APP_API_URL + "/admin/comptes/all", {
					withCredentials: true,
				})
				.then((response) => {
					response.data.forEach((compte) => {
						this.comptes.push(compte.name);
						this.compte_ids.push(compte._id);
					});
					this.comptes.sort();
					this.axios
						.get(
							process.env.VUE_APP_API_URL + "/admin/depots/all",
							{
								withCredentials: true,
							}
						)
						.then((response) => {
							var tmp = response.data;
							tmp.forEach((depot) => {
								this.depots.push(depot.name);
							});
							this.depots.sort();
							this.fetchAndProcessItemsByDepot(this.depots[0]);
						})
						.catch((error) => {
							console.log(error);
						});
				})
				.catch((error) => {
					console.log(error);
				});
		},

		update() {
			this.axios
				.get(process.env.VUE_APP_API_URL + "/admin/comptes/all", {
					withCredentials: true,
				})
				.then((response) => {
					response.data.forEach((compte) => {
						this.comptes.push(compte.name);
						this.compte_ids.push(compte._id);
					});
					this.comptes.sort();
					this.axios
						.get(
							process.env.VUE_APP_API_URL + "/admin/depots/all",
							{
								withCredentials: true,
							}
						)
						.then((response) => {
							var tmp = response.data;
							tmp.forEach((depot) => {
								this.depots.push(depot.name);
							});
							this.depots.sort();
							this.fetchAndProcessItemsByDepot(this.depots[0]);
						})
						.catch((error) => {
							console.log(error);
						});
				})
				.catch((error) => {
					console.log(error);
				});
		},

		fetchAndProcessItemsByDepot(depot) {
			this.isLoading = true;
			this.cmqs = [];
			this.axios
				.post(
					process.env.VUE_APP_API_URL +
						"/admin/contremarques/facturation",
					{
						depot: depot,
						comptes: this.compte_ids,
					},
					{
						withCredentials: true,
					}
				)
				.then((response) => {
					var cmqs = response.data;
					var clients = [];
					for (let i = 0; i < cmqs.length; i++) {
						var products = [];
						var details = [];
						var weights = [];
						var reasons = [];
						clients.push(cmqs[i].client);
						for (let j = 0; j < cmqs[i].items.length; j++) {
							products.push(cmqs[i].items[j].categorie);
							details.push(cmqs[i].items[j].details);
							weights.push(cmqs[i].items[j].weight);
							reasons.push(cmqs[i].items[j].outReason);
						}

						// Str details
						cmqs[i]["strDetails"] = this.formatList(details);

						// Str weights
						cmqs[i]["strWeights"] = this.formatList(weights);

						// Str products
						cmqs[i]["strProducts"] = this.formatList(products);

						// Str outReasons
						cmqs[i]["strOutReasons"] = this.formatList(reasons);

						// Nbr de palettes
						var nbrPalettes = 0;
						if (cmqs[i].facturation.palette == true) {
							nbrPalettes = cmqs[i].nbrPalettes;
						} else {
							nbrPalettes = 1;
						}
						cmqs[i]["nbrPalettes"] = nbrPalettes;

						// Jours restants à facturer
						var outDate = cmqs[i].outDate
							? new Date(cmqs[i].outDate)
							: new Date();
						var inDate = new Date(cmqs[i].inDate);
						outDate.setHours(0, 0, 0, 0);
						inDate.setHours(0, 0, 0, 0);
						var nbOfDays =
							(outDate - inDate) / 1000 / 60 / 60 / 24 + 1;
						var daysToBill =
							Math.max(
								nbOfDays - cmqs[i].facturation.daysBeforeBill,
								0
							) - cmqs[i].billedDays;
						cmqs[i]["daysToBill"] = daysToBill;

						// À facturer
						var toBill = parseFloat(
							(daysToBill * cmqs[i].facturation.price).toFixed(4)
						);
						cmqs[i]["toBill"] = toBill;
					}
					this.clients = Array.from(new Set(clients));
					this.clients.sort();
					this.cmqs = cmqs;
					this.isLoading = false;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		createJsonData() {
			var data = [];
			for (let i = 0; i < this.selected.length; i++) {
				let item = JSON.parse(JSON.stringify(this.selected[i]));
				delete item["_id"];
				delete item["__v"];
				if (item["outDate"]) {
					item["outDate"] = moment(item["outDate"]).format(
						"DD/MM/YYYY"
					);
				} else {
					item["outDate"] = "";
				}
				if (item["inDate"]) {
					item["inDate"] = moment(item["inDate"]).format(
						"DD/MM/YYYY"
					);
				} else {
					item["inDate"] = "";
				}
				data.push(item);
			}
			this.json_data = data;
		},
		deleteSelection() {
			this.$refs.confirm
				.open("Supprimer", "Êtes-vous sûr(e) ?", "Supprimer", {
					color: "primary",
				})
				.then((confirm) => {
					if (confirm) {
						let sizeBatch = 1;
						let nBatches = Math.floor(
							this.selected.length / sizeBatch - 1
						);
						var promises = [];
						for (let i = 0; i < nBatches + 1; i++) {
							promises.push(
								this.axios.delete(
									process.env.VUE_APP_API_URL +
										"/api/items/delete",
									{
										params: {
											items: this.selected.slice(
												i * sizeBatch,
												Math.min(
													(i + 1) * sizeBatch,
													this.selected.length
												)
											),
										},
										withCredentials: true,
									}
								)
							);
						}
						Promise.all(promises)
							.then(() => {
								this.selected = [];
								this.fetchItems();
							})
							.catch((err) => {
								console.log(err);
							});
					}
				});
		},
		editCmq(cmq) {
			this.editedContremarque = JSON.parse(JSON.stringify(cmq));
			this.$refs.gestion.open();
		},
	},
};
</script>
<style>
.v-data-table-header th {
	white-space: nowrap;
}
</style>
