<template>
	<v-dialog v-model="dialog" @keydown.esc="cancel">
		<confirm ref="confirm"></confirm>
		<v-snackbar :color="snackbarColor" v-model="snackbar" :timeout="1500">
			{{ snackbarMessage }}
			<v-btn text color="white" @click="snackbar = false">
				<v-icon center>close</v-icon>
			</v-btn>
		</v-snackbar>
		<v-card>
			<v-toolbar dark :color="options.color" dense text>
				<v-toolbar-title class="white--text"
					>Contremarque {{ contremarque.name }}</v-toolbar-title
				>
			</v-toolbar>
			<v-data-table
				:headers="headers"
				:items="contremarque.items"
				:search="search"
				:footer-props="{
					itemsPerPageText: 'Nombre d\'items par page',
					itemsPerPageOptions: [
						5,
						10,
						20,
						{ text: 'Tout', value: -1 },
					],
				}"
			>
				<template slot="items" slot-scope="props">
					<td>{{ props.item.fournisseur }}</td>
					<td>{{ props.item.outDate | formatDate }}</td>
					<td>{{ props.item.categorie }}</td>
					<td>{{ props.item.details }}</td>
					<td>{{ props.item.quantity }}</td>
					<td>{{ props.item.unity }}</td>
					<td class="justify-center layout px-0">
						<v-icon
							small
							color="success"
							class="mr-2"
							@click="validateItem(props)"
							>check</v-icon
						>
						<v-icon
							small
							color="success"
							class="mr-2"
							@click="label(props.item)"
							>label</v-icon
						>
					</td>
				</template>
				<template slot="pageText" slot-scope="props"
					>Lignes {{ props.pageStart }} à {{ props.pageStop }} sur
					{{ props.itemsLength }}</template
				>
				<v-alert
					slot="no-results"
					:value="true"
					color="error"
					icon="warning"
					>La recherche pour "{{ search }}" n'a rien donné.</v-alert
				>
			</v-data-table>
			<v-progress-linear
				v-if="isLoading"
				:indeterminate="true"
			></v-progress-linear>
		</v-card>
	</v-dialog>
</template>

<script>
import * as JsBarcode from "jsbarcode";
import * as jsPDF from "jspdf";
import confirm from "@/components/Confirm.vue";

export default {
	props: ["contremarque", "comptes"],
	components: { confirm },
	data: () => ({
		isLoading: false,
		dialog: false,
		snackbarColor: null,
		snackbarMessage: null,
		snackbar: false,
		nbrPalettes: null,
		menuEdition: false,
		livraisonFormatted: new Date(),
		dialogPalettes: false,
		search: "",
		edit: false,
		add: false,
		resolve: null,
		reject: null,
		message: null,
		title: null,
		index: null,
		options: {
			color: "primary",
		},
		headers: [
			{ text: "Fournisseur", value: "fournisseur" },
			{ text: "Date de sortie prévue prévue", value: "outDate" },
			{ text: "Catégorie", value: "categorie" },
			{ text: "Détails", value: "details" },
			{ text: "Quantité", value: "quantity" },
			{ text: "Unité", value: "unity" },
			{
				text: "Valider/Imprimer étiquette",
				sortable: false,
				value: "edition",
				align: "center",
			},
		],
		item: {
			fournisseur: "",
			categorie: "",
			details: "",
			quantity: 1,
		},
	}),
	filters: {
		formatDate(date) {
			if (!date) return null;
			date = new Date(date).toISOString().substr(0, 10);
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
	},
	watch: {
		edit(val) {
			val || this.close();
		},
		dialogPalettes(val) {
			val || this.closePalettes();
		},
	},
	methods: {
		label(item) {
			var centeredText = function (text, y) {
				var textWidth =
					(doc.getStringUnitWidth(text) *
						doc.internal.getFontSize()) /
					doc.internal.scaleFactor;
				var textOffset =
					(doc.internal.pageSize.getWidth() - textWidth) / 2;
				doc.text(textOffset, y, text);
			};
			var canvas = document.createElement("CANVAS");
			JsBarcode(canvas, item._id, {
				width: 1,
				displayValue: false,
			});
			var img = canvas.toDataURL();
			var doc = new jsPDF({
				orientation: "landscape",
				unit: "in",
				format: [2, 1],
			});
			var width = doc.internal.pageSize.getWidth();
			var height = doc.internal.pageSize.getHeight();

			doc.setFontSize(5);
			doc.addImage(img, "JPEG", 0, 0, width, height * 0.5);
			centeredText(item._id, height * 0.55);
			centeredText("Fournisseur: " + item.fournisseur, height * 0.65);
			centeredText("Client: " + item.client, height * 0.75);
			centeredText(
				"Contremarque: " + item.contremarque.name,
				height * 0.85
			);
			doc.autoPrint();
			window.open(doc.output("bloburl"), "_blank");
		},
		formatDate(date) {
			if (!date) return null;
			date = new Date(date).toISOString().substr(0, 10);
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
		validateItem(props) {
			var item = props.item;
			this.index = props.index;
			this.$refs.confirm
				.open(
					"Valider",
					"Êtes-vous sûr(e) de valider la réception du produit  " +
						item.name +
						" ?",
					"Valider",
					{
						color: "primary",
					}
				)
				.then((confirm) => {
					if (confirm) {
						var ids = [];
						this.$props.comptes.forEach((compte) => {
							ids.push(compte._id);
						});
						if (
							this.comptes[
								ids.indexOf(item.contremarque.facturation)
							].palette
						) {
							this.dialogPalettes = true;
						} else {
							this.reception(item);
						}
					}
				});
		},
		closePalettes() {
			this.dialogPalettes = false;
			this.nbrPalettes = null;
		},

		savePalettes() {
			var item = this.$props.contremarque.items[this.index];
			this.dialogPalettes = false;
			this.receptionPalettes(item);
		},
		reception(item) {
			this.isLoading = true;
			this.axios
				.post(
					process.env.VUE_APP_API_URL + "/api/items/validate",
					item,
					{
						withCredentials: true,
					}
				)
				.then(() => {
					this.isLoading = false;
					this.snackbarColor = "success";
					this.snackbarMessage = "Réception validée";
					this.snackbar = true;
					this.$props.contremarque.items.splice(this.index, 1);
					if (this.$props.contremarque.items == 0) {
						this.$emit("fetchContremarques");
						this.close();
					}
				})
				.catch((error) => {
					this.isLoading = false;
					this.snackbarColor = "error";
					this.snackbarMessage =
						"Erreur lors de la validation de la réception";
					this.snackbar = true;
					console.log(error);
				});
		},
		receptionPalettes(item) {
			this.isLoading = true;
			this.axios
				.post(
					process.env.VUE_APP_API_URL +
						"/api/items/validateWithPallettes",
					{ item, nbrPalettes: this.nbrPalettes },
					{ withCredentials: true }
				)
				.then(() => {
					this.isLoading = false;
					this.nbrPalettes = null;
					this.snackbarColor = "success";
					this.snackbarMessage = "Réception validée";
					this.snackbar = true;
					this.$props.contremarque.items.splice(this.index, 1);
					if (this.$props.contremarque.items == 0) {
						this.$emit("fetchContremarques");
						this.close();
					}
				})
				.catch((error) => {
					this.isLoading = false;
					this.nbrPalettes = null;
					this.snackbarColor = "error";
					this.snackbarMessage =
						"Erreur lors de la validation de la réception";
					this.snackbar = true;
					console.log(error);
				});
		},
		open() {
			this.dialog = true;
			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			});
		},
		close() {
			this.dialog = false;
			setTimeout(() => {
				this.index = -1;
			}, 300);
		},
	},
};
</script>
