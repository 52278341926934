<template>
	<div>
		<confirm ref="confirm"></confirm>
		<v-form>
			<v-container fluid>
				<v-subheader class="text-h6"
					>Ajouter un emplacement</v-subheader
				>
				<v-row>
					<v-col xs12 md6>
						<v-text-field
							v-model="emplacement.name"
							label="Nom"
							required
							autocomplete="off"
						></v-text-field>
					</v-col>

					<v-col xs12 md6>
						<v-text-field
							v-model="emplacement.code"
							label="Code"
							required
							autocomplete="off"
						>
						</v-text-field>
					</v-col>
				</v-row>
				<div class="text-center mb-4">
					<v-btn outlined color="primary" v-on:click="addEmplacement"
						>Ajouter</v-btn
					>
				</div>
			</v-container>
		</v-form>
		<v-data-table
			v-if="emplacements.length > 0"
			:headers="headers"
			:items="emplacements"
			class="elevation-1"
			hideDefaultFooter
		>
			<template v-slot:[`item.edition`]="{ item }">
				<v-icon
					small
					color="primary"
					class="mr-2"
					@click="editEmplacement(item)"
					>edit</v-icon
				>
				<v-icon small color="error" @click="deleteEmplacement(item)"
					>delete</v-icon
				>
			</template>
		</v-data-table>
		<v-dialog v-model="dialog" max-width="500px">
			<v-card>
				<v-card-title>
					<span class="text-h5">Éditer</span>
				</v-card-title>

				<v-card-text>
					<v-container grid-list-md>
						<v-row wrap align-center justify-center>
							<v-col>
								<v-text-field
									v-model="editedItem.name"
									label="Nom"
								></v-text-field>
							</v-col>
							<v-col xs12 md6>
								<v-text-field
									v-model="editedItem.code"
									label="Code"
								></v-text-field>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="close"
						>Annuler</v-btn
					>
					<v-btn color="blue darken-1" text @click="save"
						>Sauvegarder</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import confirm from "@/components/Confirm.vue";
export default {
	components: { confirm },
	data: function () {
		return {
			dialog: false,
			emplacements: [],
			emplacement: {
				name: null,
				code: null,
			},
			headers: [
				{ text: "Emplacement", value: "name" },
				{ text: "Code", value: "code" },
				{
					text: "Éditer/Supprimer",
					sortable: false,
					value: "edition",
					align: "center",
				},
			],
			editedItem: {
				name: null,
				code: null,
			},
		};
	},
	created() {
		this.fetchEmplacements();
	},
	watch: {
		dialog(val) {
			val || this.close();
		},
	},
	methods: {
		fetchEmplacements() {
			this.axios
				.get(
					process.env.VUE_APP_API_URL + "/api/emplacements/bydepot",
					{
						withCredentials: true,
					}
				)
				.then((response) => {
					this.emplacements = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		addEmplacement() {
			this.axios
				.put(
					process.env.VUE_APP_API_URL + "/api/emplacements/add",
					this.emplacement,
					{ withCredentials: true }
				)
				.then(() => {
					this.emplacement.name = null;
					this.emplacement.code = null;
					this.fetchEmplacements();
				})
				.catch((error) => {
					console.log(error);
				});
		},
		editEmplacement(item) {
			this.editedIndex = this.emplacements.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},
		deleteEmplacement(item) {
			const index = this.emplacements.indexOf(item);
			this.$refs.confirm
				.open("Supprimer", "Êtes-vous sûr(e) ?", "Supprimer", {
					color: "primary",
				})
				.then((confirm) => {
					if (confirm) {
						this.axios
							.delete(
								process.env.VUE_APP_API_URL +
									"/api/emplacements/delete",
								{
									params: { id: item._id },
									withCredentials: true,
								}
							)
							.then(() => {
								this.emplacements.splice(index, 1);
							})
							.catch((err) => {
								console.log(err);
							});
					}
				});
		},
		close() {
			this.dialog = false;
			setTimeout(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			}, 300);
		},

		save() {
			if (this.editedIndex > -1) {
				this.axios
					.post(
						process.env.VUE_APP_API_URL +
							"/api/emplacements/update",
						this.editedItem,
						{
							withCredentials: true,
						}
					)
					.then(() => {
						Object.assign(
							this.emplacements[this.editedIndex],
							this.editedItem
						);
					})
					.catch((err) => {
						console.log(err);
					});
			} else {
				this.axios
					.post(
						process.env.VUE_APP_API_URL +
							"/api/emplacements/update",
						this.editedItem,
						{
							withCredentials: true,
						}
					)
					.then(() => {
						this.emplacements.push(this.editedItem);
					})
					.catch((err) => {
						console.log(err);
					});
			}
			this.close();
		},
	},
};
</script>
