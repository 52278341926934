<template>
	<div>
		<confirm ref="confirm"></confirm>
		<v-snackbar :color="snackbarColor" v-model="snackbar" :timeout="1500">
			{{ snackbarMessage }}
			<v-btn text color="white" @click="snackbar = false">
				<v-icon center>close</v-icon>
			</v-btn>
		</v-snackbar>
		<v-container fluid>
			<v-card>
				<v-card-title
					class="text-uppercase text-h5 font-weight-regular primary--text"
					>Comptes de facturation
				</v-card-title>
				<v-form>
					<v-container fluid>
						<v-subheader class="text-h6"
							>Ajouter un compte client</v-subheader
						>
						<v-row>
							<v-col xs12 md6>
								<v-text-field
									v-model="compte.name"
									label="Nom"
									id="name"
									autocomplete="off"
								>
								</v-text-field>
							</v-col>
							<v-col xs12 md6>
								<v-text-field
									v-model="compte.mail"
									label="Mail"
									id="mail"
									autocomplete="off"
								>
								</v-text-field>
							</v-col>
						</v-row>

						<v-row>
							<v-col xs12 md6>
								<v-text-field
									v-model="compte.price"
									label="Prix de la facturation au jour"
									id="price"
									autocomplete="off"
								></v-text-field>
							</v-col>
							<v-col xs12 md6>
								<v-text-field
									v-model="compte.daysBeforeBill"
									label="Nombre de jours avant début de facturation"
									id="daysBeforeBill"
									autocomplete="off"
								>
								</v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col xs12 md6>
								<v-checkbox
									v-model="compte.palette"
									label="Facturation au nombre de palettes ?"
								>
								</v-checkbox>
							</v-col>
						</v-row>
						<div class="text-center mb-4">
							<v-btn
								outlined
								color="primary"
								v-on:click="addCompte"
								>Ajouter</v-btn
							>
						</div>
					</v-container>
				</v-form>
				<v-data-table
					:headers="headers"
					:items="comptes"
					:search="search"
					:footer-props="{
						itemsPerPageText: 'Nombre de produits par page',
						itemsPerPageOptions: [
							25,
							50,
							100,
							{ text: 'Tout', value: -1 },
						],
					}"
				>
					<template v-slot:[`item.palette`]="{ item }">
						{{ item.palette ? "Oui" : "Non" }}
					</template>
					<template v-slot:[`item.edition`]="{ item }">
						<v-icon
							small
							color="primary"
							class="mr-2"
							@click="editItem(item)"
							>edit</v-icon
						>
						<v-icon small color="error" @click="deleteItem(item)"
							>delete</v-icon
						>
					</template>
					<template slot="no-data">
						<v-alert
							:value="true"
							color="primary"
							icon="info"
							outlined
							>Pas compte créé</v-alert
						>
					</template>
					<template slot="footer.page-text" slot-scope="props">
						Lignes {{ props.pageStart }} à {{ props.pageStop }} sur
						{{ props.itemsLength }}
					</template>
					<v-alert
						slot="no-results"
						:value="true"
						color="error"
						icon="warning"
						>La recherche pour "{{ search }}" n'a rien
						donné.</v-alert
					>
				</v-data-table>
				<v-dialog v-model="dialog" max-width="1000px">
					<v-card>
						<v-card-title>
							<span class="text-h5">Éditer</span>
						</v-card-title>

						<v-card-text>
							<v-container grid-list-md>
								<v-row wrap>
									<v-col xs12 md6>
										<v-text-field
											v-model="editedItem.name"
											label="Client"
										></v-text-field>
									</v-col>
									<v-col xs12 md6>
										<v-text-field
											v-model="editedItem.mail"
											label="Mail"
										></v-text-field>
									</v-col>
								</v-row>

								<v-row>
									<v-col xs12 md6>
										<v-text-field
											v-model="editedItem.price"
											label="Prix de la facturation au jour"
										></v-text-field>
									</v-col>
									<v-col xs12 md6>
										<v-text-field
											v-model="editedItem.daysBeforeBill"
											label="Nombre de jours avant début de facturation"
										></v-text-field>
									</v-col>
									<v-col xs12 md6>
										<v-checkbox
											v-model="editedItem.palette"
											label="Facturation au nombre de palettes ?"
										></v-checkbox>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" text @click="close"
								>Annuler</v-btn
							>
							<v-btn color="primary" text @click="save"
								>Sauvegarder</v-btn
							>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-card>
		</v-container>
	</div>
</template>
<script>
import confirm from "@/components/Confirm.vue";
export default {
	components: { confirm },
	data: function () {
		return {
			snackbarColor: null,
			snackbarMessage: null,
			snackbar: false,
			dialog: false,
			comptes: [],
			search: null,
			compte: {
				name: null,
				mail: null,
				price: null,
				daysBeforeBill: null,
				palette: false,
			},
			headers: [
				{ text: "Client", value: "name" },
				{ text: "Mail", value: "mail" },
				{ text: "Prix/jour", value: "price" },
				{ text: "Jours avant facturation", value: "daysBeforeBill" },
				{ text: "Facturation/palette", value: "palette" },
				{
					text: "Éditer/Supprimer",
					sortable: false,
					value: "edition",
					align: "center",
				},
			],
			editedItem: {
				name: null,
				mail: null,
				price: null,
				daysBeforeBill: null,
				palette: null,
			},
		};
	},
	created() {
		this.fetchComptes();
	},
	watch: {
		dialog(val) {
			val || this.close();
		},
	},
	methods: {
		fetchComptes() {
			this.axios
				.get(process.env.VUE_APP_API_URL + "/admin/comptes/all", {
					withCredentials: true,
				})
				.then((response) => {
					this.comptes = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		addCompte() {
			this.axios
				.put(
					process.env.VUE_APP_API_URL + "/admin/comptes/add",
					this.compte,
					{
						withCredentials: true,
					}
				)
				.then(() => {
					this.compte.name = null;
					this.compte.mail = null;
					this.compte.price = null;
					this.compte.daysBeforeBill = null;
					this.compte.palette = false;
					this.fetchComptes();
				})
				.catch((error) => {
					this.snackbarColor = "Error" + error;
					this.snackbarMessage =
						"Erreur ! Les éléments saisis ne sont pas valides";
					this.snackbar = true;
				});
		},
		editItem(item) {
			this.editedIndex = this.comptes.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},
		deleteItem(item) {
			const index = this.comptes.indexOf(item);
			this.$refs.confirm
				.open("Supprimer", "Êtes-vous sûr(e) ?", "Supprimer", {
					color: "primary",
				})
				.then((confirm) => {
					if (confirm) {
						this.axios
							.delete(
								process.env.VUE_APP_API_URL +
									"/admin/comptes/delete",
								{
									params: { id: item._id },
									withCredentials: true,
								}
							)
							.then(() => {
								this.comptes.splice(index, 1);
							})
							.catch((err) => {
								console.log(err);
							});
					}
				});
		},
		close() {
			this.dialog = false;
			setTimeout(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			}, 300);
		},

		save() {
			if (this.editedIndex > -1) {
				this.axios
					.post(
						process.env.VUE_APP_API_URL + "/admin/comptes/update",
						this.editedItem,
						{
							withCredentials: true,
						}
					)
					.then(() => {
						Object.assign(
							this.comptes[this.editedIndex],
							this.editedItem
						);
					})
					.catch((err) => {
						console.log(err);
					});
			} else {
				this.axios
					.post(
						process.env.VUE_APP_API_URL + "/admin/comptes/update",
						this.editedItem,
						{
							withCredentials: true,
						}
					)
					.then(() => {
						this.comptes.push(this.editedItem);
					})
					.catch((err) => {
						console.log(err);
					});
			}
			this.close();
		},
	},
};
</script>
