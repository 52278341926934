<template>
	<v-dialog v-model="dialog" @keydown.esc="cancel">
		<v-card>
			<v-card-title>
				<span class="text-h5"
					>Éditer la contremarque {{ cmq.name }}</span
				>
			</v-card-title>
			<v-card-text>
				<v-container grid-list-md>
					<v-layout wrap>
						<v-flex xs12 sm6 md6>
							<v-menu
								v-model="menuEditionIn"
								:close-on-content-click="false"
								:nudge-right="40"
								lazy
								transition="scale-transition"
								offset-y
								min-width="290px"
							>
								<template v-slot:activator="{ on }">
									<v-text-field
										v-model="inDateFormatted"
										label="Date de réception"
										prepend-icon="event"
										v-on="on"
									></v-text-field>
								</template>
								<v-date-picker
									v-model="cmq.inDate"
									@input="menuEditionIn = false"
									locale="fr"
									first-day-of-week="1"
									no-title
								></v-date-picker>
							</v-menu>
						</v-flex>
						<v-flex xs12 sm6 md6>
							<v-menu
								v-model="menuEditionOut"
								:close-on-content-click="false"
								:nudge-right="40"
								lazy
								transition="scale-transition"
								offset-y
								min-width="290px"
							>
								<template v-slot:activator="{ on }">
									<v-text-field
										v-model="outDateFormatted"
										label="Date de sortie"
										prepend-icon="event"
										v-on="on"
									></v-text-field>
								</template>
								<v-date-picker
									v-model="cmq.outDate"
									@input="menuEditionOut = false"
									locale="fr"
									first-day-of-week="1"
									no-title
								></v-date-picker>
							</v-menu>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 sm6 md6>
							<v-autocomplete
								v-model="cmq.client"
								:items="clients"
								label="Client"
								single-line
								hide-details
								clearable
								hide-no-data
							></v-autocomplete>
						</v-flex>
						<v-flex xs12 sm6 md6>
							<v-autocomplete
								v-model="cmq.facturation.name"
								:items="namesComptes"
								label="Comptes"
								single-line
								hide-details
								clearable
								hide-no-data
							></v-autocomplete>
						</v-flex>
					</v-layout>
					<v-layout wrap align-center justify-center>
						<v-flex xs12 sm6 md6>
							<v-data-table
								v-if="cmq.items.length > 0"
								:headers="headers"
								:items="cmq.items"
								hideDefaultFooter
							>
								<template v-slot:[`item.outReason`]="{ item }">
									<v-select
										:items="outReasons"
										v-model="item.outReason"
									>
									</v-select>
								</template>
							</v-data-table>
						</v-flex>
					</v-layout>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" text @click="cancel">Annuler</v-btn>
				<v-btn color="primary" text @click="save">Sauvegarder</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: ["cmq", "depot", "updateFunction"],
	data: function () {
		return {
			menuEditionIn: false,
			menuEditionOut: false,
			outDateFormatted: null,
			inDateFormatted: null,
			clients: [],
			comptes: [],
			namesComptes: [],
			dialog: false,
			edit: false,
			add: false,
			resolve: null,
			reject: null,
			message: null,
			title: null,
			outReasons: [
				"Livraison",
				"Retrait client",
				"Retrait par magasin",
				"Retour fournisseur",
			],
			options: {
				color: "primary",
			},
			headers: [
				{ text: "Produit", value: "categorie" },
				{ text: "Motif de sortie", value: "outReason" },
			],
		};
	},
	filters: {
		formatDate(date) {
			if (!date) return null;
			date = new Date(date).toISOString().substr(0, 10);
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
	},
	watch: {
		edit(val) {
			val || this.close();
		},
		cmq: {
			handler() {
				if (this.cmq.outDate != null) {
					this.outDateFormatted = this.formatDate(this.cmq.outDate);
					// this.cmq.outDate = new Date(this.cmq.outDate)
					// 	.toISOString()
					// 	.substr(0, 10);
				}
				if (this.cmq.inDate != null) {
					this.inDateFormatted = this.formatDate(this.cmq.inDate);
					// this.cmq.outDate = new Date(this.cmq.outDate)
					// 	.toISOString()
					// 	.substr(0, 10);
				}
			},
			deep: true,
		},
	},
	methods: {
		fetchComptes() {
			this.axios
				.get(process.env.VUE_APP_API_URL + "/api/comptes/all", {
					withCredentials: true,
				})
				.then((response) => {
					this.comptes = response.data;
					response.data.forEach((item) => {
						this.namesComptes.push(item.name);
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		fetchClients() {
			this.axios
				.post(
					process.env.VUE_APP_API_URL + "/admin/clients/bydepot",
					{ depot: this.$props.depot },
					{
						withCredentials: true,
					}
				)
				.then((response) => {
					response.data.forEach((item) => {
						this.clients.push(item.name);
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		formatDate(date) {
			if (!date) return null;
			date = new Date(date).toISOString().substr(0, 10);
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
		open() {
			this.dialog = true;
			return new Promise((resolve, reject) => {
				if (this.clients.length == 0) {
					this.fetchClients();
				}
				if (this.comptes.length == 0) {
					this.fetchComptes();
				}
				this.resolve = resolve;
				this.reject = reject;
			});
		},
		agree() {
			this.resolve(true);
			this.edit = false;
		},
		cancel() {
			this.outDateFormatted = null;
			this.inDateFormatted = null;
			this.resolve(false);
			this.dialog = false;
		},
		save() {
			var cmqBody = {};
			var index = this.namesComptes.indexOf(this.cmq.facturation.name);
			var cpt = this.comptes[index];
			cmqBody.id = this.cmq._id;
			cmqBody.updates = {};
			cmqBody.updates.inDate = this.cmq.inDate;
			cmqBody.updates.outDate = this.cmq.outDate
				? this.cmq.outDate
				: null;
			cmqBody.updates.client = this.cmq.client;
			cmqBody.updates.facturation = cpt._id;

			var itemsBody = [];
			this.cmq.items.forEach((item) => {
				itemsBody.push({
					id: item._id,
					updates: { outReason: item.outReason },
				});
			});
			this.axios
				.post(
					process.env.VUE_APP_API_URL + "/admin/contremarques/update",
					cmqBody,
					{
						withCredentials: true,
					}
				)
				.then(() => {
					this.axios.post(
						process.env.VUE_APP_API_URL + "/admin/items/update",
						itemsBody,
						{
							withCredentials: true,
						}
					);
					this.updateFunction(this.depot);
					this.cancel();
				})
				.catch((error) => {
					this.cancel();
					console.log(error);
				});
		},
	},
};
</script>
