<template>
	<v-container fluid>
		<v-snackbar :color="snackbarColor" v-model="snackbar" :timeout="1500">
			{{ snackbarMessage }}
			<v-btn text color="white" @click="snackbar = false">
				<v-icon center>close</v-icon>
			</v-btn>
		</v-snackbar>
		<v-card>
			<v-card-title
				class="text-uppercase text-h5 font-weight-regular primary--text"
			>
				Sélectionner les produits en préparation
				<v-spacer></v-spacer>
				<v-text-field
					v-model="search"
					append-icon="search"
					label="Rechercher un item"
					single-line
					hide-details
					clearable
				></v-text-field>
			</v-card-title>
			<v-data-table
				v-model="selected"
				:headers="headers"
				:items="items"
				:search="search"
				show-select
				item-key="_key"
				:footer-props="{
					itemsPerPageText: 'Nombre de produits par page',
					itemsPerPageOptions: [
						25,
						50,
						100,
						{ text: 'Tout', value: -1 },
					],
				}"
			>
				<template v-slot:[`item.contremarque.inDate`]="{ item }">
					{{ item.contremarque.inDate | moment("LL") }}
				</template>
				<template v-slot:[`item.outDate`]="{ item }">
					{{ item.outDate | moment("LL") }}
				</template>
				<template v-slot:[`item.quantity`]="{ item }">
					<v-edit-dialog @save="save(item)" @close="cleanQuantity">
						{{ item.quantity }}
						<template v-slot:input>
							<v-text-field
								v-model="preparedQuantity"
								label="Edit"
								single-line
							></v-text-field>
						</template>
					</v-edit-dialog>
				</template>
				<template slot="no-data">
					<v-alert :value="true" color="primary" icon="info" outlined
						>Pas de produit à passer en préparation
					</v-alert>
				</template>
				<template slot="footer.page-text" slot-scope="props">
					Lignes {{ props.pageStart }} à {{ props.pageStop }} sur
					{{ props.itemsLength }}
				</template>
				<v-alert
					slot="no-results"
					:value="true"
					color="error"
					icon="warning"
					>La recherche pour "{{ search }}" n'a rien donné.</v-alert
				>
			</v-data-table>
		</v-card>
		<v-card v-if="selected.length > 0">
			<v-card-title
				class="text-uppercase text-h5 font-weight-regular primary--text"
				>Sélection</v-card-title
			>
			<v-data-table
				:headers="selected_headers"
				:items="selected"
				hideDefaultFooter
			>
				<template v-slot:[`item.contremarque.inDate`]="{ item }">
					{{ item.contremarque.inDate | moment("LL") }}
				</template>
				<template v-slot:[`item.outDate`]="{ item }">
					{{ item.outDate | moment("LL") }}
				</template>
				<template v-slot:[`item.details`]="{ item }">
					<tr v-if="item.removeQuantity">
						<v-edit-dialog>
							{{ item.details }}
							<template v-slot:input>
								<v-text-field
									v-model="item.details"
									label="Edit"
									single-line
								></v-text-field>
							</template>
						</v-edit-dialog>
					</tr>
					<tr v-else>
						{{
							item.details
						}}
					</tr>
				</template>
				<template v-slot:[`item.contremarque.name`]="{ item }">
					<tr v-if="item.removeQuantity">
						<v-edit-dialog
							@save="addToUpdateCmq(item)"
							@close="cleanCmqName"
						>
							{{ item.contremarque.name }}
							<template v-slot:input>
								<v-text-field
									v-model="newContremarqueName"
									label="Edit"
									single-line
								></v-text-field>
							</template>
						</v-edit-dialog>
					</tr>
					<tr v-else>
						{{
							item.contremarque.name
						}}
					</tr>
				</template>
				<template v-slot:[`item.outReason`]="{ item }">
					<v-select :items="outReasons" v-model="item.outReason">
					</v-select>
				</template>
			</v-data-table>
		</v-card>
		<div class="text-center" v-if="selected.length > 0">
			<v-btn
				:loading="isLoading"
				class="mt-3"
				color="primary"
				id="button"
				@click="preparation"
				>Valider la préparation</v-btn
			>
		</div>
	</v-container>
</template>

<script>
export default {
	name: "Preparation",
	components: {},
	data: function () {
		return {
			snackbarColor: null,
			snackbarMessage: null,
			snackbar: false,
			selected: [],
			search: "",
			items: [],
			isLoading: false,
			preparedQuantity: null,
			newContremarqueName: null,
			outReasons: [
				"Livraison",
				"Retrait client",
				"Retrait par magasin",
				"Retour fournisseur",
			],
			headers: [
				{ text: "Date de réception", value: "contremarque.inDate" },
				{ text: "Date de sortie", value: "outDate" },
				{ text: "Catégorie", value: "categorie" },
				{ text: "Détails", value: "details" },
				{ text: "Poids", value: "weight" },
				{ text: "Fournisseur", value: "fournisseur" },
				{ text: "Client", value: "client" },
				{ text: "Contremarque", value: "contremarque.name" },
				{ text: "Quantité", value: "quantity" },
				{ text: "Unité", value: "unity" },
				{ text: "Localisation", value: "localisation.name" },
			],
			selected_headers: [
				{ text: "Date de réception", value: "contremarque.inDate" },
				{ text: "Date de sortie", value: "outDate" },
				{ text: "Catégorie", value: "categorie" },
				{ text: "Détails", value: "details" },
				{ text: "Poids", value: "weight" },
				{ text: "Fournisseur", value: "fournisseur" },
				{ text: "Client", value: "client" },
				{ text: "Contremarque", value: "contremarque.name" },
				{ text: "Quantité", value: "quantity" },
				{ text: "Unité", value: "unity" },
				{ text: "Localisation", value: "localisation.name" },
				{ text: "Motif de sortie", value: "outReason" },
			],
		};
	},
	created() {
		this.fetchItems();
	},
	methods: {
		cleanQuantity() {
			this.preparedQuantity = null;
		},
		cleanCmqName() {
			this.newContremarqueName = null;
		},
		fetchItems() {
			this.axios
				.get(process.env.VUE_APP_API_URL + "/api/items/inreception", {
					withCredentials: true,
				})
				.then((response) => {
					this.items = response.data;
					var i = 0;
					this.items.forEach((item) => {
						if (item.localisation == undefined) {
							item.localisation = {};
							item.localisation.name = "Scannez la localisation";
						}

						if (item.details == "") {
							item.details = "N/A";
						}

						item._key = i;
						i += 1;
						item.outReason = "Livraison";
						if (
							item.outDate == undefined ||
							item.outDate === null
						) {
							item.outDate = new Date()
								.toISOString()
								.substr(0, 10);
						}
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		addToUpdateCmq(item) {
			item.contremarque.name = this.newContremarqueName;
			if (item.contremarque.name != item.originalContremarqueName) {
				item.updateContremarque = true;
			} else {
				item.updateContremarque = false;
			}
		},
		remove(item) {
			const index = this.items.indexOf(item);
			this.items.splice(index, 1);
		},
		save(item) {
			let newItem = structuredClone(item);
			newItem.originalContremarqueName = newItem.contremarque.name; // To check the contremarque has been updated
			newItem.quantity = Math.min(
				parseInt(this.preparedQuantity),
				newItem.quantity - 1
			);
			newItem._key = this.items.length + 1;
			newItem.removeQuantity = true;
			item.quantity = Math.max(
				1,
				item.quantity - parseInt(this.preparedQuantity)
			);
			newItem.remainingQuantity = item.quantity;
			let index = this.items.indexOf(item);
			this.items.splice(index + 1, 0, newItem);
			this.selected.push(newItem);
			this.preparedQuantity = null;
		},
		preparation() {
			this.isLoading = true;
			this.axios
				.post(
					process.env.VUE_APP_API_URL + "/api/items/preparation",
					this.selected,
					{ withCredentials: true }
				)
				.then(() => {
					this.selected.forEach((item) => {
						this.remove(item);
					});
					this.selected = [];
					this.isLoading = false;
					this.snackbarColor = "success";
					this.snackbarMessage = "Préparation validée";
					this.snackbar = true;
				})
				.catch((error) => {
					this.isLoading = false;
					this.snackbarColor = " error";
					this.snackbarMessage = "Erreur" + error;
					this.snackbar = true;
					console.log(error);
				});
		},
	},
};
</script>
<style>
.v-select {
	font-size: 1em;
}
</style>
