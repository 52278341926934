import axios from "axios";
import moment from "moment-timezone";
import Vue from "vue";
import VueAxios from "vue-axios";
import JsonExcel from "vue-json-excel";
import VueMoment from "vue-moment";
import VueParticles from "vue-particles";
import VueSweetalert2 from "vue-sweetalert2";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";

const options = {
	confirmButtonColor: "#0D47A1",
	cancelButtonColor: "#ff5252",
};

Vue.component("downloadExcel", JsonExcel);
Vue.use(VueSweetalert2, options);
Vue.use(VueParticles);
Vue.use(VueAxios, axios);
Vue.use(VueMoment, {
	moment,
});
Vue.moment.locale("fr");
Vue.config.productionTip = false;

new Vue({
	router,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
