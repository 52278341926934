<template>
	<div>
		<v-snackbar
			:color="snackbarColor"
			v-model="snackbar"
			:timeout="2500"
			top
		>
			{{ snackbarMessage }}
			<template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					text
					v-bind="attrs"
					@click="snackbar = false"
				>
					<v-icon center>close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
		<confirm ref="confirm"></confirm>
		<v-form>
			<v-container fluid>
				<v-row>
					<v-col xs6 md6>
						<v-autocomplete
							v-model="client"
							:items="clients"
							label="Client"
							single-line
							hide-details
							clearable
							hide-no-data
						></v-autocomplete>
					</v-col>
					<v-col xs6 md6>
						<v-autocomplete
							v-model="compte"
							:items="namesComptes"
							label="Compte de facturation"
							single-line
							hide-details
							clearable
							hide-no-data
						></v-autocomplete>
					</v-col>
				</v-row>
				<v-row>
					<v-col xs6 md6>
						<v-text-field
							v-model="contremarque"
							label="Contremarque"
							id="contremarque"
							required
							autocomplete="off"
						></v-text-field>
					</v-col>
					<v-col xs6 md6>
						<v-text-field
							v-model="ref"
							label="N° dossier"
							id="ref"
							required
							autocomplete="off"
						>
						</v-text-field>
					</v-col>
				</v-row>
				<v-subheader class="text-h6"
					>Ajouter des produits à la pré-réception</v-subheader
				>
				<v-divider></v-divider>
				<v-row>
					<v-col xs12 md6>
						<v-autocomplete
							id="fournisseur"
							v-model="fournisseur"
							:items="fournisseurs"
							label="Fournisseur"
							single-line
							hide-details
							clearable
							hide-no-data
						></v-autocomplete>
					</v-col>
					<v-col xs6 md6>
						<v-menu
							v-model="menu"
							:close-on-content-click="false"
							:nudge-right="40"
							lazy
							transition="scale-transition"
							offset-y
							full-width
							min-width="290px"
						>
							<template v-slot:activator="{ on }">
								<v-text-field
									v-model="dateFormatted"
									label="Date de sortie prévue"
									prepend-icon="event"
									readonly
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="date"
								@input="menu = false"
								locale="fr"
								first-day-of-week="1"
								no-title
							></v-date-picker>
						</v-menu>
					</v-col>
				</v-row>
				<v-row>
					<v-col xs12 md2>
						<v-autocomplete
							v-model="categorie"
							:items="categories"
							label="Catégorie"
							single-line
							hide-details
							clearable
							hide-no-data
						></v-autocomplete>
					</v-col>
					<v-col xs12 md2>
						<v-text-field
							v-model="details"
							label="Détails"
							required
						></v-text-field>
					</v-col>
					<v-col xs12 md2>
						<v-text-field
							v-model="weight"
							label="Poids"
							required
						></v-text-field>
					</v-col>
					<v-col xs12 md2>
						<v-text-field
							v-model="quantity"
							label="Quantité"
							required
						></v-text-field>
					</v-col>
					<v-col xs12 md2>
						<v-select
							:items="quantityTypes"
							v-model="unity"
							label="Unité"
							required
						></v-select>
					</v-col>
				</v-row>
				<div class="text-center">
					<v-btn outlined color="primary" v-on:click="addItem"
						>Ajouter le produit</v-btn
					>
				</div>
				<br />
				<v-data-table
					v-if="items.length > 0"
					:headers="headers"
					:items="items"
					class="elevation-1"
					hideDefaultFooter
				>
					<template v-slot:[`item.outDate`]="{ item }">
						{{ item.outDate | formatDate }}
					</template>
					<template v-slot:[`item.edition`]="{ item }">
						<v-icon small color="error" @click="deleteItem(item)"
							>delete</v-icon
						>
					</template>
				</v-data-table>
				<div class="text-center">
					<v-btn
						class="mt-3"
						:loading="isLoading"
						color="primary"
						v-on:click="validerPrereception"
						>Valider la pré-réception</v-btn
					>
				</div>
			</v-container>
		</v-form>
	</div>
</template>
<script>
import confirm from "@/components/Confirm.vue";
export default {
	name: "addPreReception",
	components: { confirm },
	data: function () {
		return {
			date: null,
			dateFormatted: null,
			menu: false,
			menuEdition: false,
			clients: [],
			fournisseurs: [],
			categories: [],
			snackbarColor: null,
			snackbarMessage: null,
			snackbar: false,
			search: "",
			isLoading: false,
			items: [],
			item: JSON,
			categorie: "",
			details: "",
			weight: null,
			quantity: new Number(1),
			quantityTypes: ["colis", "palette", "longueur", "unité"],
			unity: "colis",
			fournisseur: "",
			client: "",
			status: "",
			ref: "",
			contremarque: "",
			contremarques: [],
			comptes: [],
			namesComptes: [],
			compte: "",
			cmq: JSON,
			headers: [
				{ text: "Fournisseur", value: "fournisseur" },
				{ text: "Catégorie", value: "categorie" },
				{ text: "Détails", value: "details" },
				{ text: "Poids", value: "weight" },
				{ text: "Quantité", value: "quantity" },
				{ text: "Unité", value: "unity" },
				{ text: "Date de sortie prévue", value: "outDate" },
				{
					text: "Supprimer",
					sortable: false,
					value: "edition",
					align: "center",
				},
			],
		};
	},
	filters: {
		formatDate(date) {
			if (!date) return null;

			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
	},
	created() {
		this.fetchClients();
		this.fetchFournisseurs();
		this.fetchCategories();
		this.fetchComptes();
	},
	watch: {
		date() {
			this.dateFormatted = this.formatDate(this.date);
		},
		compte() {
			this.compteId = this.formatDate(this.date);
		},
	},
	methods: {
		fetchClients() {
			this.axios
				.get(process.env.VUE_APP_API_URL + "/api/clients/all", {
					withCredentials: true,
				})
				.then((response) => {
					response.data.forEach((item) => {
						this.clients.push(item.name);
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		fetchComptes() {
			this.axios
				.get(process.env.VUE_APP_API_URL + "/api/comptes/all", {
					withCredentials: true,
				})
				.then((response) => {
					this.comptes = response.data;
					response.data.forEach((item) => {
						this.namesComptes.push(item.name);
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		fetchFournisseurs() {
			this.axios
				.get(process.env.VUE_APP_API_URL + "/api/fournisseurs/all", {
					withCredentials: true,
				})
				.then((response) => {
					response.data.forEach((item) => {
						this.fournisseurs.push(item.name);
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		fetchCategories() {
			this.axios
				.get(process.env.VUE_APP_API_URL + "/api/categories/all", {
					withCredentials: true,
				})
				.then((response) => {
					response.data.forEach((item) => {
						this.categories.push(item.name);
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		formatDate(date) {
			if (!date) return null;

			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
		addItem() {
			if (!this.client || !this.compte) {
				this.snackbarColor = "warning";
				this.snackbarMessage =
					"Veuillez ajouter un client et/ou un compte de facturation avant de valider";
				this.snackbar = true;
				return -1;
			}
			this.item = {
				categorie: this.categorie,
				details: this.details,
				weight: this.weight,
				quantity: this.quantity,
				unity: this.unity,
				fournisseur: this.fournisseur,
				client: this.client,
				contremarque: this.contremarque,
				status: "prereception",
				outDate: this.date,
				ref: this.ref,
			};

			this.items.push(this.item);
			var input = document.getElementById("fournisseur");
			input.focus();
			input.select();
			this.categorie = "";
			this.details = "";
			this.weight = null;
			this.quantity = 1;
			this.unity = "colis";
			this.item = {};
		},
		deleteItem(item) {
			const index = this.items.indexOf(item);
			this.items.splice(index, 1);
		},
		validerPrereception() {
			if (this.items.length == 0) {
				this.snackbarColor = "warning";
				this.snackbarMessage =
					"Veuillez ajouter un produit avant de valider";
				this.snackbar = true;
			} else {
				this.$refs.confirm
					.open(
						"Valider la pré-réception",
						"Êtes-vous sûr(e) de valider ?",
						"Valider",
						{
							color: "primary",
						}
					)
					.then((confirm) => {
						let cpt = null;
						let index = this.namesComptes.indexOf(this.compte);
						cpt = this.comptes[index];
						if (confirm) {
							this.isLoading = true;
							this.cmq = {
								name: this.contremarque,
								client: this.client,
								items: [],
								facturation: cpt._id,
								ref: this.ref,
							};
							this.axios
								.put(
									process.env.VUE_APP_API_URL +
										"/api/contremarques/add",
									this.cmq,
									{ withCredentials: true }
								)
								.then((cmqInserted) => {
									this.items.forEach((item) => {
										item.contremarque =
											cmqInserted.data._id;
									});
									this.axios
										.put(
											process.env.VUE_APP_API_URL +
												"/api/items/add",
											this.items,
											{
												withCredentials: true,
											}
										)
										.then((docsInserted) => {
											var ids = [];
											docsInserted.data.forEach(
												(item) => {
													ids.push(item._id);
												}
											);
											this.axios
												.post(
													process.env
														.VUE_APP_API_URL +
														"/api/contremarques/update",
													{
														cmq: cmqInserted.data
															._id,
														id: ids,
													},
													{
														withCredentials: true,
													}
												)
												.then(() => {
													this.items = [];
													this.snackbarColor =
														"success";
													this.snackbarMessage =
														"Pré-réception validée";
													this.snackbar = true;
													this.ref = "";
													this.client = "";
													this.date = null;
													this.contremarque = "";
													this.compte = "";
													this.cmq = {};
													this.$emit(
														"fetchContremarques"
													);
													this.isLoading = false;
												});
										});
								})
								.catch((error) => {
									this.snackbarColor = "error";
									this.snackbarMessage = "Erreur " + error;
									this.snackbar = true;
									this.isLoading = false;
									console.log(error);
								});
						}
					});
			}
		},
	},
};
</script>
