<template>
	<v-dialog
		v-model="dialog"
		:max-width="options.width"
		@keydown.esc="cancel"
		v-bind:style="{ zIndex: options.zIndex }"
	>
		<v-card>
			<v-toolbar dark :color="options.color" dense text>
				<v-toolbar-title class="white--text">{{
					title
				}}</v-toolbar-title>
			</v-toolbar>
			<br />
			<v-card-text v-show="!!message">{{ message }}</v-card-text>
			<v-card-actions class="pt-0">
				<v-spacer></v-spacer>
				<v-btn color="primary darken-1" text @click.native="agree">{{
					validation
				}}</v-btn>
				<v-btn color="grey" text @click.native="cancel">Annuler</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "Confirm",
	data: () => ({
		dialog: false,
		resolve: null,
		reject: null,
		message: null,
		title: null,
		validation: null,
		options: {
			color: "primary",
			width: 290,
			zIndex: 200,
		},
	}),
	methods: {
		open(title, message, validation, options) {
			this.dialog = true;
			this.title = title;
			this.message = message;
			this.validation = validation;
			this.options = Object.assign(this.options, options);
			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			});
		},
		agree() {
			this.resolve(true);
			this.dialog = false;
		},
		cancel() {
			this.resolve(false);
			this.dialog = false;
		},
	},
};
</script>
